import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { userList, updateUser, deleteUser } from "../../Services/CommonServices";
import UserEdit from "./UserEdit";
import WithLayout from "../../Shared/Components/HeaderFooterHOC";
import { useGlobalState } from "../../Context";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from 'primereact/inputswitch';
import loader from "../../Assets/images/loader-1.png";
import DialogBox from "../../Shared/Components/DialogBox";


const UserContainer = (props) => {
  const [users, setUsers] = useState([]);
  const [userEditData, setUserEditData] = React.useState();
  const [displayDialog, setDisplayDialog] = React.useState({
    edit: false,
    delete: false,
    status: false
  });
  const [product, setProduct] = useState([]);
  const [showLoader, setLoader] = useState(false);



  useEffect(() => {
    setLoader(true)
    userList().then((list) => {
      setUsers(list.data);
      setLoader(false)

    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const showDialog = (type, show) => {
    if (type === "edit") {
      setDisplayDialog((preval) => ({ ...preval, edit: show }));
    }

    if (type === "delete") {
      setDisplayDialog((preval) => ({ ...preval, delete: show }));
    }
    if(type === "status") {
      setDisplayDialog((preval) => ({ ...preval, status: show }));
    }
  };

  const editUserPopup = (userData) => {
    setUserEditData({ ...userData });
    showDialog("edit", true);
  };

  const editUser = (formObj) => {
    setLoader(true)
    updateUser(formObj.id, {
      nickname: formObj.nickname,
      gender: formObj.gender,
    })
      .then((res) => {
        if (res) {
          showDialog("edit", false);
          setLoader(false)
          props.showToast("success", "Success", "Tenant updated.");
          if (res.projects_not_deleted && res.projects_not_deleted.length > 0) {
            props.showToast(
              "error",
              "Error",
              `Catogory must be empty before they can be removed. Delete all URLs added by the Tenant under categories(${res.projects_not_deleted.toString()})  and retry.`,
              5000
            );
          }
          userList().then((list) => {
            setUsers(list.data);
          });
        }
      })
      .catch(() => {
        setLoader(false)
        props.showToast("error", "Error", "Error updating user");
      });
  };
  

  const confirmDeleteUser = (userData) => {
    setProduct(userData);
    showDialog("delete", true);
  };

  const hideDeleteUserDialog = () => {
    showDialog("delete", false);
  };

  const deleteUserData = async () => { 

    setLoader(true)
    showDialog("delete", false);
    deleteUser(product.id,"delete")
    .then(async res => {      
      
      await userList().then((list) => {
        setUsers(list.data);
      });
      setLoader(false);
      props.showToast("success", "Success", res.data.message)

      // console.log(res)
    })
    .catch(err=>{
      console.log(err);
      setLoader(false)

    })
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil" title="Edit"
          className="p-button-rounded p-button-primary p-button-outlined"
          onClick={() => editUserPopup(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger p-button-outlined"
          onClick={() => confirmDeleteUser(rowData)}
        />
      </React.Fragment>
    );
  };

  //status 
  const statusBodyTemplate = (rowData) => {
    return (
      <InputSwitch checked={rowData.active} onChange={(e)=>statusModal(rowData, e.value)}/>
    )
  }

  const [statusType, setStatusType] = useState('');

  const statusModal = (rowData,value) => {
    setProduct(rowData);
    showDialog("status", true);
    value ? setStatusType('enable') : setStatusType('disable')
  }

  // hide delete confirmation modal
  const hideStatusProductDialog = () => {
    showDialog("status", false);
  };

  const setStatus = (value) => {

    hideStatusProductDialog();
    setLoader(true);

    if(value === 'enable') {
      deleteUser(product.id,"enable")
      .then(res=>{
          props.showToast("success", "Success", "User Enabled.");
          userList().then((list) => {
            setUsers(list.data);
            setLoader(false)
      
          });
      })
      .catch(err=>{
        console.log(err);
        props.showToast("error", "Error", "Something went wrong !");
        setLoader(false);
      })

    } else {
      deleteUser(product.id,"disable")
      .then(res=>{
          props.showToast("success", "Success", "User Disabled.");
          userList().then((list) => {
            console.log(list);
            setUsers(list.data);
            setLoader(false)
      
          });
      })
      .catch(err=>{
        console.log(err);
        props.showToast("error", "Error", "Something went wrong !");
        setLoader(false);
      })

    }
  }

  return (
    <>
    <div
        className="loader-overlay"
        style={{ display: showLoader ? "flex" : "none" }}
      >
        <div className="p-d-flex p-flex-column p-ai-center">
          <div className="loader">
            <img src={loader} width="100" height="100" />
          </div>
          <span className="loading-text p-mt-2">Loading... Please Wait...</span>
        </div>
      </div>
      <div>
        {displayDialog.edit && (
          <UserEdit
            {...{
              userEditData,
              show: displayDialog.edit,
              showDialog,
              editUser,
            }}
          />
        )}
      </div>
      <div>
        {displayDialog.status && (
          <div>
            <DialogBox area="user" type={statusType} showDialog={displayDialog.status} 
                        hideDialog={hideStatusProductDialog} product={product}
                        confirmClick={()=>setStatus(statusType)}/>
          </div>        
        )}
      </div>

      <div>
        {displayDialog.delete && (
          <div>
          <DialogBox area="user" type="delete" showDialog={displayDialog.delete} 
                      hideDialog={hideDeleteUserDialog} product={product}
                      confirmClick={()=>deleteUserData()}/>
        </div>
        )}
      </div>
      <div>
        <h2 className="main-heading">Users</h2>
        <div>
          <DataTable value={users} removableSort>
            <Column
              field="phone_number"
              header="Phone Number"
              sortable
            ></Column>
            <Column field="email" header="Email" sortable></Column>
            <Column field="nickname" header="Nick name" sortable></Column>
            <Column field="gender" header="Gender" sortable></Column>
            <Column header="Status" body={statusBodyTemplate} sortable></Column>
            <Column header="Actions" body={actionBodyTemplate}></Column>
          </DataTable>
        </div>
      </div>
    </>
  );
};

export default WithLayout(UserContainer);
