import React from "react";
import { TEXT_LABELS } from "../../Utilities/TextLabels";
import { Dialog } from "primereact/dialog";
import { showFormValidation, validateEmail } from "../../Utilities/Utils";
import ButtonWrapper from "../../Shared/Components/Button";

import InputTextWrapper from "../../Shared/Components/InputText";
const UserEdit = ({ ...props }) => {
  // console.log("tenantEditData======", props);
  const [formObj, setFormObj] = React.useState({
    id: props.userEditData.id,
    nickname: props.userEditData.nickname,
    gender: props.userEditData.gender,
  });
  const [submitted, setSubmitted] = React.useState(false);

  const onEditTenantSubmit = () => {
    setSubmitted(true);
    if (formObj.nickname && formObj.gender) {
      props.editUser(formObj);
    }
  };

  React.useEffect(() => {
    setFormObj({
      id: props.userEditData.id,
      nickname: props.userEditData.nickname,
      gender: props.userEditData.gender,
    });
  }, [props.userEditData]);

  const setInput = (event) => {
    setSubmitted(false);
    const { name, value } = event.target;
    setFormObj({ ...formObj, [name]: value });
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonWrapper
          label={TEXT_LABELS.commonLables.cancel}
          onClick={() => props.showDialog("edit", false)}
          className="p-button p-component p-button-rounded p-button-secondary p-button-icon-only"
          icon="pi pi-times"
        />
        <ButtonWrapper
          className="p-button p-component p-button-rounded p-button-success p-button-icon-only"
          label={TEXT_LABELS.commonLables.update}
          onClick={onEditTenantSubmit}
          icon="pi pi-save"
        />
      </div>
    );
  };

  return (
    <Dialog
      visible={props.show}
      footer={renderFooter()}
      header={TEXT_LABELS.userManagementModule.editUser}
      onHide={() => props.showDialog("edit", false)}
      style={{ width: "40vw" }}
      modal
    >

      {/* <div className="p-fluid p-formgrid p-grid small-popup-content">
        <div className="p-field p-col-12">
          <label htmlFor={TEXT_LABELS.fieldNames.user.nickname}>
            {TEXT_LABELS.fieldNames.user.nickname} <span> *</span>{" "}
          </label>
          <div className="popup-field">
            <InputTextWrapper
              name={TEXT_LABELS.fieldNames.user.nickname}
              type="text"
              value={formObj.nickname}
              autoFocus
              onChange={(e) => setInput(e)}
            />
            {submitted && !formObj.nickname
              ? showFormValidation(
                true,
                true,
                TEXT_LABELS.tenantModule.requiredAddFrom.name
              )
              : showFormValidation(false)}
          </div>
        </div>
        <div className="p-field p-col-12">
          <label htmlFor={TEXT_LABELS.fieldNames.user.gender}>
            {TEXT_LABELS.fieldNames.user.gender} <span> *</span>
          </label>
          <div className="popup-field">
            <InputTextWrapper
              name={TEXT_LABELS.fieldNames.user.gender}
              type="text"
              value={formObj.gender}
              onChange={(e) => setInput(e)}
            />
            {submitted && !formObj.gender
              ? showFormValidation(
                true,
                true,
                TEXT_LABELS.tenantModule.requiredAddFrom.gender
              )
              : ""}
          </div>
        </div>
      </div> */}





      <div className="row mt-3">
        <div className="col-md-12">

          <div className="form-floating mb-3">
            <InputTextWrapper
              name={TEXT_LABELS.fieldNames.user.nickname}
              type="text"
              className="w-100 form-control"
              value={formObj.nickname}
              // autoFocus
              onChange={(e) => setInput(e)}
            />
            {submitted && !formObj.nickname
              ? showFormValidation(
                true,
                true,
                TEXT_LABELS.tenantModule.requiredAddFrom.name
              )
              : showFormValidation(false)}
            <label htmlFor="{TEXT_LABELS.fieldNames.user.nickname}">
              {TEXT_LABELS.fieldNames.user.nickname} <span className="red"> *</span>{" "}
            </label>
          </div>



        </div>


        <div className="col-md-12">
          <div className="form-floating mb-3">
            <InputTextWrapper
              name={TEXT_LABELS.fieldNames.user.gender}
              type="text"
              className="w-100 form-control"
              value={formObj.gender}
              onChange={(e) => setInput(e)}
            />
            {submitted && !formObj.gender
              ? showFormValidation(
                true,
                true,
                TEXT_LABELS.tenantModule.requiredAddFrom.gender
              )
              : ""}
            <label htmlFor={TEXT_LABELS.fieldNames.user.gender}>
              {TEXT_LABELS.fieldNames.user.gender} <span className="red"> *</span>
            </label>
          </div>
        </div>


      </div>


    </Dialog>
  );
};

export default UserEdit;
