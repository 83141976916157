import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";

const WithLayout = (Component) => ({ ...props }) => (
  <div className="d-flex main-container" id="wrapper">
    {/* <Footer /> */}
    <Sidebar {...props} />
    <div id="page-content-wrapper">
      <Header {...props} />
      <div className="page-content py-3 px-3">
        <Component {...props} />
      </div>
    </div>
  </div>
);

export default WithLayout;
