import Auth from "@aws-amplify/auth";
import { ManageLocalStorage } from "../Services/ManageLocalStorage";
import { userRoles } from "../Utilities/Utils";
const { SuperAdmin, TenantAdmin } = userRoles;

const loginApi = async (params) => {
  ManageLocalStorage.set("password", params.password);
  ManageLocalStorage.set("username", params.username);
  return Auth.signIn(params.username, params.password).then(
    (response) => response
  );
};
export async function loginUser(dispatch, loginPayload) {
  try {
    dispatch({ type: "REQUEST_LOGIN" });
    let response = await loginApi(loginPayload);
    if (response && response.challengeName === "NEW_PASSWORD_REQUIRED") {
      dispatch({ type: "NEW_PASSWORD_REQUIRED", payload: response });
      return response;
    }

    if (
      response &&
      response.username &&
      (response.attributes["custom:user_role"] === SuperAdmin ||
        response.attributes["custom:user_role"] === TenantAdmin)
    ) {
      dispatch({ type: "LOGIN_SUCCESS", payload: response });
      return response;
    }

    dispatch({ type: "LOGIN_ERROR", error: response.errors[0] });
    return;
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", error: error });
    throw error;
  }
}

export const logout = async (dispatch) => {
  await Auth.signOut();
  ManageLocalStorage.clear();
  dispatch({ type: "LOGOUT" });
};
