import React, { useEffect, useState } from 'react';

import { getLibraryItem, createLibraryItem, updateLibraryItem, fileUpload } from "../../Services/CommonServices";
import { showFormValidation } from "../../Utilities/Utils";
import loader from "../../Assets/images/loader-1.png";
import WithLayout from "../../Shared/Components/HeaderFooterHOC";

const AddLibrary = (props) => {
    const [formObj, setFormObj] = useState({type: ""});
    const [submitted, setSubmitted] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [fileValue, setFileValue] = useState("");
    
    const libraryType = [
        { label: 'Downloads', value: 'downloads' },
        { label: 'Quotes', value: 'quotes' },
        { label: 'Links', value: 'links' }
    ];

    useEffect(() => {
        setShowLoader(true);

        const id = props.match.params.id;
        id ? setEditMode(true) : setEditMode(false);

        if (id) {
            getLibraryItem(id)
                .then(res => {
                    patchData(res.data)
                })
                .catch(err => {
                    console.log(err);
                })
        } else {
            setTimeout(() => {
                setShowLoader(false);
            }, 500);
        }

        return () => {
            // componentWillUnmount events
        }

    }, []);

    const patchData = (data) => {
        if(data.type === 'downloads'){
            setFormObj({
                description: data.description ? data.description : '',
                content_url: data.content_url ? data.content_url : '',
                image_url: data.image_url ? data.image_url : '',
                type: data.type
            })
        }

        if(data.type === 'quotes'){
            setFormObj({
                author: data.author ? data.author : '',
                description: data.description ? data.description : '',
                image_url: data.image_url ? data.image_url : '',
                type: data.type
            })
        }

        if(data.type === 'links'){
            setFormObj({
                description: data.description ? data.description : '',
                content_url: data.content_url ? data.content_url : '',
                image_url: data.image_url ? data.image_url : '',
                type: data.type
            })
        }

        setTimeout(() => {
            setShowLoader(false);
        }, 500);
    }

    const setInput = (event) => {
        event.preventDefault()
        const { name, value } = event.target;
        setFormObj({ ...formObj, [name]: value });
    };

    const libTypeHandler = (event) => {
        event.preventDefault();
        setSubmitted(false);
        const { name, value } = event.target;
        setFormObj({ [name]: value });
    }

    // Capitalize
    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    // select a file 
    const fileChanged = async (e, type , isThumb) => {

        const file_name = e.target.files[0].name;

        e.preventDefault();
        if (e.target.files.length === 0) {
            return;
        }
        setShowLoader(true);

        if (!isThumb && e.target.files[0].size > 9999999) {
            props.showToast("error", "Error", "Maximum file size 10MB");
            setShowLoader(false);
            return;
        }else if(isThumb && e.target.files[0].size > 49999){
            props.showToast("error", "Error", "Maximum file size 500kB");
            setShowLoader(false);
            return;
        }

        let encodedString;
        await getBase64(e.target.files[0])
            .then(res => {
                encodedString = res;
            })
            .catch(error => {
                props.showToast("error", "Error", "Something went wrong. Try again !");
                setShowLoader(false);
            })

        const body = {
            "file_name": file_name,
            "type": isThumb ? 'lib-thumb' : 'download-file',
            "base64_string": encodedString
        }

        // const file_type = e.target.files[0].type.split('/')[0];
        fileUpload(body)
        .then((flp) => {
            setShowLoader(false);
            if (flp.data.url){
                if(type === 'downloads' && !isThumb){
                    setFormObj({ ...formObj, content_url: flp.data.url.replace('ap-south-1','us-east-1'),});
                }else{
                    setFormObj({ ...formObj, image_url: flp.data.url.replace('ap-south-1','us-east-1'),});
                }
            }
        })
        .catch(err => {
            console.log(err);
            if (err.request) { console.log(err.request) } if (err.response) { console.log(err.response) }
        });
    };

    // convert file to base64
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {

            let baseURL = "";

            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                baseURL = reader.result.replace("data:", "").replace(/^.+,/, "");

                if (baseURL) {
                    resolve(baseURL);
                } else {
                    reject("error")
                }

            };
        });
    };

    const removeMediaURL = (type) => {
        if(type === 'downloads'){
            setFormObj({ ...formObj, content_url: "" });
        }
    }

    const removeThumbURL = (type) => {
        setFormObj({ ...formObj, image_url: "" });
    }

    const onAddLibrarySubmit = (e, formObj) => {
        e.preventDefault();
        setSubmitted(true);

        if ((formObj.description && formObj.content_url && formObj.image_url && formObj.type === 'downloads') || 
            (formObj.author && formObj.description && formObj.image_url && formObj.type === 'quotes') ||
            (formObj.description && formObj.content_url && formObj.image_url && formObj.type === 'links')
        ){
            createLibraryItem(formObj)
                .then(res => {
                    setShowLoader(false);
                    setSubmitted(false);
                    props.showToast("success", "Success", res.data.message);
                    props.history.push('/library');
                })
                .catch(err => {
                    console.log(err);
                    setShowLoader(false);
                    props.showToast("error", "Error", "Something went wrong");
                })
        }
    }

    const onEditLibrarySubmit = (e, formObj) => {
        e.preventDefault();
        setSubmitted(true);

        const id = props.match.params.id;
        
        if ((formObj.description && formObj.content_url && formObj.image_url && formObj.type === 'downloads') || 
            (formObj.author && formObj.description && formObj.image_url && formObj.type === 'quotes') ||
            (formObj.description && formObj.content_url && formObj.image_url && formObj.type === 'links')
        ){
            updateLibraryItem(id, formObj)
                .then(res => {
                    setShowLoader(false);
                    setSubmitted(false);
                    props.showToast("success", "Success", res.data.message);                    
                    props.history.push(`/library`);
                })
                .catch(err => {
                    console.log(err);
                    setShowLoader(false);
                    props.showToast("error", "Error", "Something went wrong");
                })
        }

    }

    return (
        <>
            <div className="loader-overlay" style={{ display: showLoader ? "flex" : "none" }} >
                <div className="p-d-flex p-flex-column p-ai-center">
                    <div className="loader">
                        <img src={loader} width="100" height="100" />
                    </div>
                    <span className="loading-text p-mt-2">Loading... Please Wait...</span>
                </div>
            </div>
            <div className="row">
                <div className="col-md-8 col-xl-6 col-xxl-6 mx-auto">
                    <div className="main-heading-wrapper d-flex align-items-center justify-content-between mb-2">

                        <div className="d-flex align-items-center">
                            <button className="btn btn-clear me-2" onClick={() => props.history.push('/library')}>
                                <span className="icon icon-arrow_back"></span>
                            </button>
                            {
                                editMode ? <h4 className="main-heading mb-0">Edit Library Item</h4>
                                    : <h4 className="main-heading mb-0">Create Library Item</h4>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-page-content">
                <div className="row">
                    <div className="col-md-8 col-xl-6 col-xxl-6 mx-auto">
                        <div className="card p-5">
                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-12">
                                        {
                                        editMode ? 
                                            <div className="form-floating mb-3">
                                                <input type="text" name="type" className="form-control" id="libraryType"
                                                    value={capitalizeFirstLetter(formObj.type)} placeholder="Library Type" disabled/>
                                                <label htmlFor="libraryType">Library Type</label>
                                            </div>
                                        :
                                            <div className="form-floating mb-3">
                                                <select value={formObj.type} onChange={(e) => libTypeHandler(e)} className="form-select"
                                                    id="libraryType" aria-label="Library Type" name="type">
                                                    <option value="" disabled >Select</option>
                                                    {
                                                        libraryType.map((item, index) => (
                                                            <option key={index} value={item.value}>
                                                                {item.label}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                                {submitted && !formObj.type
                                                    ? showFormValidation(true, true, 'Select a Library type')
                                                    : showFormValidation(false)
                                                }
                                                <label htmlFor="libraryType">Library Type  <span className="red">*</span></label>
                                            </div>
                                        }
                                        </div>

                                        <style jsx="true">
                                            {
                                            `
                                                .library-media .include-media{
                                                    padding: 1rem 0.75rem;
                                                    position: relative;
                                                    bottom: 1px;
                                                    left: 1px;
                                                    width: calc(100% - 2px);
                                                    border-top: none;
                                                }
                                                .library-media input[type="file"] {
                                                    display: none;
                                                }
                                                .remove-uploads{
                                                    font-size: 0.8rem !important;
                                                    font-weight: 600 !important;
                                                    color: red;
                                                    cursor: pointer;
                                                }
                                            `
                                            }
                                        </style>

                                        {
                                            formObj.type === 'downloads' ?
                                            <div>
                                                <div className="col-md-12">
                                                    <div className="form-floating mb-3">
                                                        <input type="text" name="description" className="form-control" id="downloadName"
                                                            value={formObj.description ? formObj.description : '' } onChange={(e) => setInput(e)} placeholder="Download Name" />
                                                        {submitted && !formObj.description
                                                            ? showFormValidation(true, true, 'Download Name required')
                                                            : showFormValidation(false)
                                                        }
                                                        <label htmlFor="downloadName">Name <span className="red">*</span></label>
                                                    </div>
                                                </div>

                                                {/* <div className="col-md-12">
                                                    <div className="form-floating mt-3">
                                                        <textarea className="form-control" name="download_description" placeholder="Download Description"
                                                            value={formObj.download_description} onChange={(e) => setInput(e)} id="download-desc" rows="4" />
                                                        {submitted && !formObj.download_description
                                                            ? showFormValidation(true, true, 'Description required')
                                                            : showFormValidation(false)
                                                        }
                                                        <label htmlFor="download-desc">Download Description <span className="red">*</span></label>
                                                    </div>
                                                </div> */}

                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-floating mb-3">
                                                            <div className="library-media">
                                                                <div className="include-media d-flex align-items-center">
                                                                    <span className="me-2">
                                                                        Upload Media
                                                                    </span>
                                                                    <ul className="m-0 p-0 d-flex align-items-center">
                                                                        <li style={{opacity: formObj.content_url ? ".3" : "1"}}>
                                                                            <label
                                                                                className="media"
                                                                                htmlFor="downloads_media_id"
                                                                                style={{cursor: formObj.content_url ? 'not-allowed' : 'pointer'}}
                                                                            >
                                                                                <span className="icon icon-file_upload" title="Upload"></span>
                                                                            </label>
                                                                            <input
                                                                                disabled= {formObj.content_url ? true : false}
                                                                                id="downloads_media_id"
                                                                                type="file"
                                                                                name="content_url"
                                                                                value={fileValue}
                                                                                onChange={(e) => fileChanged(e, formObj.type, false)}
                                                                            />
                                                                        </li>
                                                                        {formObj.content_url &&
                                                                            <li className="p-ml-2">
                                                                                <span className="icon icon-close remove-uploads" title="Remove" onClick={() => removeMediaURL(formObj.type)}></span>
                                                                            </li>
                                                                        }
                                                                    </ul>
                                                                    <div className='p-ml-3 data-size-restriction-label'>
                                                                        *Max size should be 10MB
                                                                    </div>
                                                                </div>
                                                                <div className="ps-3">
                                                                    {submitted && !formObj.content_url
                                                                        ? showFormValidation(true, true, 'Download Media required')
                                                                        : showFormValidation(false)
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-floating mb-3">
                                                            <div className="library-media">
                                                                <div className="include-media d-flex align-items-center">
                                                                    <span className="me-2">
                                                                        Upload Thumbnail
                                                                    </span>
                                                                    <ul className="m-0 p-0 d-flex align-items-center">
                                                                        <li style={{opacity: formObj.image_url ? ".3" : "1"}}>
                                                                            <label
                                                                                className="media"
                                                                                htmlFor="downloads_thumb_id"
                                                                                style={{cursor: formObj.image_url ? 'not-allowed' : 'pointer'}}
                                                                            >
                                                                                <span className="icon icon-file_upload" title="Upload"></span>
                                                                            </label>
                                                                            <input
                                                                                disabled= {formObj.image_url ? true : false}
                                                                                id="downloads_thumb_id"
                                                                                type="file"
                                                                                accept='image/*'
                                                                                name="image_url"
                                                                                value={fileValue}
                                                                                onChange={(e) => fileChanged(e, formObj.type, true)}
                                                                            />
                                                                        </li>
                                                                        {formObj.image_url &&
                                                                            <li className="p-ml-2">
                                                                                <span className="icon icon-close remove-uploads" title="Remove" onClick={() => removeThumbURL(formObj.type)}></span>
                                                                            </li>
                                                                        }
                                                                    </ul>
                                                                    <div className='p-ml-3 data-size-restriction-label'>
                                                                        *Max size should be 500KB
                                                                    </div>
                                                                </div>
                                                                <div className="ps-3">
                                                                    {submitted && !formObj.image_url
                                                                        ? showFormValidation(true, true, 'Thumbnail required')
                                                                        : showFormValidation(false)
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                            : null
                                        }

                                        {
                                            formObj.type === 'quotes' ?
                                            <div>
                                                <div className="col-md-12">
                                                    <div className="form-floating mb-3">
                                                        <input type="text" name="author" className="form-control" id="Author"
                                                            value={formObj.author ? formObj.author : ''} onChange={(e) => setInput(e)} placeholder="Author Name" />
                                                        {submitted && !formObj.author
                                                            ? showFormValidation(true, true, 'Author Name required')
                                                            : showFormValidation(false)
                                                        }
                                                        <label htmlFor="Author">Author Name <span className="red">*</span></label>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-floating mt-3">
                                                        <textarea className="form-control" name="description" placeholder="Quote"
                                                            value={formObj.description ? formObj.description : ''} onChange={(e) => setInput(e)} id="quote" rows="4" />
                                                        {submitted && !formObj.description
                                                            ? showFormValidation(true, true, 'Quote required')
                                                            : showFormValidation(false)
                                                        }
                                                        <label htmlFor="quote">Quote <span className="red">*</span></label>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-floating mb-3">
                                                            <div className="library-media">
                                                                <div className="include-media d-flex align-items-center">
                                                                    <span className="me-2">
                                                                        Upload Thumbnail
                                                                    </span>
                                                                    <ul className="m-0 p-0 d-flex align-items-center">
                                                                        <li style={{opacity: formObj.image_url ? ".3" : "1"}}>
                                                                            <label
                                                                                className="media"
                                                                                htmlFor="quotes_thumb_id"
                                                                                style={{cursor: formObj.image_url ? 'not-allowed' : 'pointer'}}
                                                                            >
                                                                                <span className="icon icon-file_upload" title="Upload"></span>
                                                                            </label>
                                                                            <input
                                                                                disabled= {formObj.image_url ? true : false}
                                                                                id="quotes_thumb_id"
                                                                                type="file"
                                                                                accept='image/*'
                                                                                name="image_url"
                                                                                value={fileValue}
                                                                                onChange={(e) => fileChanged(e, formObj.type, true)}
                                                                            />
                                                                        </li>
                                                                        {formObj.image_url &&
                                                                            <li className="p-ml-2">
                                                                                <span className="icon icon-close remove-uploads" title="Remove" onClick={() => removeThumbURL(formObj.type)}></span>
                                                                            </li>
                                                                        }
                                                                    </ul>
                                                                        
                                                                    <div className='p-ml-3 data-size-restriction-label'>
                                                                        *Max size should be 500KB
                                                                    </div>
                                                                </div>
                                                                <div className="ps-3">
                                                                    {submitted && !formObj.image_url
                                                                        ? showFormValidation(true, true, 'Thumbnail requied')
                                                                        : showFormValidation(false)
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                            : null
                                        }

                                        {
                                            formObj.type === 'links' ?
                                            <div>
                                                <div className="col-md-12">
                                                    <div className="form-floating mb-3">
                                                        <input type="text" name="description" className="form-control" id="articleTitle"
                                                            value={formObj.description ? formObj.description : ''} onChange={(e) => setInput(e)} placeholder="Article Title" />
                                                        {submitted && !formObj.description
                                                            ? showFormValidation(true, true, 'Article Title required')
                                                            : showFormValidation(false)
                                                        }
                                                        <label htmlFor="articleTitle">Article title <span className="red">*</span></label>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-floating mb-3">
                                                        <input type="text" name="content_url" className="form-control" id="articleUrl"
                                                            value={formObj.content_url ? formObj.content_url : ''} onChange={(e) => setInput(e)} placeholder="Artile URL" />
                                                        {submitted && !formObj.content_url
                                                            ? showFormValidation(true, true, 'Artile URL required')
                                                            : showFormValidation(false)
                                                        }
                                                        <label htmlFor="articleUrl">Article URL <span className="red">*</span></label>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-floating mb-3">
                                                            <div className="library-media">
                                                                <div className="include-media d-flex align-items-center">
                                                                    <span className="me-2">
                                                                        Upload Thumbnail
                                                                    </span>
                                                                    <ul className="m-0 p-0 d-flex align-items-center">
                                                                        <li style={{opacity: formObj.image_url ? ".3" : "1"}}>
                                                                            <label
                                                                                className="media"
                                                                                htmlFor="links_thumb_id"
                                                                                style={{cursor: formObj.image_url ? 'not-allowed' : 'pointer'}}
                                                                            >
                                                                                <span className="icon icon-file_upload" title="Upload"></span>
                                                                            </label>
                                                                            <input
                                                                                disabled= {formObj.image_url ? true : false}
                                                                                id="links_thumb_id"
                                                                                type="file"
                                                                                accept='image/*'
                                                                                name="image_url"
                                                                                value={fileValue}
                                                                                onChange={(e) => fileChanged(e, formObj.type, true)}
                                                                            />
                                                                        </li>
                                                                        {formObj.image_url &&
                                                                            <li className="p-ml-2">
                                                                                <span className="icon icon-close remove-uploads" title="Remove" onClick={() => removeThumbURL(formObj.type)}></span>
                                                                            </li>
                                                                        }
                                                                    </ul>
                                                                        
                                                                    <div className='p-ml-3 data-size-restriction-label'>
                                                                        *Max size should be 500KB
                                                                    </div>
                                                                </div>
                                                                <div className="ps-3">
                                                                    {submitted && !formObj.image_url
                                                                        ? showFormValidation(true, true, 'Thumbnail required')
                                                                        : showFormValidation(false)
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-2 text-center form-save-btns">
                                <div className="col-md-12">
                                    <button onClick={() => props.history.push('/library')} title="Cancel" className="p-button p-component p-button-rounded p-button-secondary p-mr-2 p-button-icon-only btn-lg" >
                                        <i className='pi pi-times'></i>
                                    </button>
                                    {
                                        editMode ?
                                        <>
                                        <button onClick={(e) => onEditLibrarySubmit(e, formObj)} title="Update"
                                            className="p-button p-component p-button-rounded p-button-success p-button-icon-only btn-lg" >
                                            <i className='pi pi-save'></i>
                                        </button>
                                        </> 
                                        :
                                        <>
                                        <button className="p-button p-component p-button-rounded p-px-4 p-py-3 p-ml-2"
                                            onClick={(e) => onAddLibrarySubmit(e, formObj)}>Save Library Item
                                        </button>
                                        </>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WithLayout(AddLibrary)
