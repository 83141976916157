import React from "react";

import { Dropdown } from 'primereact/dropdown';

import InputTextWrapper from "../../Shared/Components/InputText";

const StoryOption = (props) => {

  if (props.toggleOption) {

    const indexPosition = props.optionDetails.length;

    return props.optionDetails.map((val, idx) => {
      // console.log(val, idx)
      let story_name = `story_name-${idx}`;
      let story_option = `story_option-${idx}`;
      return (
        <>
          <div className="option-wrapper mt-3" key={idx}>
            <div className="row align-items-center mb-3">
              <div className="col-md-6 pe-0">
                <div className="d-flex align-items-center">
                  <div className="option-number me-2">{idx + 1}.</div>
                  <div className="option-text flex-grow-1">
                    <div className="form-floating">
                      <InputTextWrapper
                        id={story_name}
                        name="story_name"
                        type="text"
                        required
                        className="form-control"
                        autoFocus={false}
                        autoComplete="off"
                        placeholder="Story"
                        // disabled= {props.editStory ? false : true}
                        data-id={idx}
                        key={idx}
                        onChange={(e) => props.onChange(idx, e)}
                        value={ Object.values(props.optionDetails[idx]['story_name'])}
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault() }}

                      />
                      <label htmlFor="login_username">Option {idx + 1}</label>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-md-5 ps-0">
                <div className="option-story-link-wrap d-flex align-items-center">

                  <div className="text-center link-icon px-2">
                    <span className="icon icon-link"></span>
                  </div>


                  <div className="form-floating flex-grow-1">

                    <select
                      className="form-select"
                      aria-label="Floating label select example"
                      name="story_option"
                      id={story_option}
                      data-id={idx}
                      key={idx}
                      value={props.optionDetails[idx]['story_option']}
                      onChange={(e) => props.onChange(idx, e)}

                    >
                      <option value="" disabled>Please select</option>
                      <option value=""></option>
                      {props.stories.map((r, i) => (
                        <option key={i} value={r.id}>
                          {r.story_name}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="floatingSelect">Select Story</label>
                  </div>
                </div>
              </div> */}

              <div className="col-md-1 py-1">
                {
                  idx !== 0
                    ?
                    <button className="btn btn-danger" onClick={(e) => props.delete(e, val) && e.preventDefault()}
                      onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault() }}>
                      <span className="icon icon-remove"></span>
                    </button>
                    : <button className="btn btn-danger" onClick={(e) => { indexPosition > 1 ? props.delete(e, val) : props.clearInput(e, val); e.preventDefault() }}
                      onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault() }} >
                      <span className="icon icon-remove"></span>
                    </button>
                }
              </div>
            </div>
          </div>
          {
            idx < 4 ?
              idx === indexPosition - 1 ? (
                <div className="row">
                  <div className="col-md-6 text-end"></div>
                  <div className="col-md-1">
                    <button onClick={(e) => props.add(e)} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      type="button" className={idx !== 0 ? "btn btn-primary text-center buttoner" : "btn btn-primary text-center"} >
                      <span className="icon icon-add"></span>
                    </button>
                  </div>
                </div>
              ) : null
              : null
          }

        </>
      );
    });
  } else {
    return <></>
  }
};
export default StoryOption;
