export const TEXT_LABELS = {
  commonLables: {
    fetchingData: "Fetching Data",
    noRecords: "No Records!",
    select: "Select",
    cancel: "Cancel",
    save: "Save",
    add: "Add",
    reset: "Reset and Save",
    update: "Update",
    reqField: "This field is required",
    allReqField: "All fields are required",
    uniqueField: "Field names must be unique",
    confirmation: "Confirmation",
  },
  fieldNames: {
    tenant: {
      companyName: "tenant_name",
      email: "email",
      domain_name: "domain_name",
      projects: "project_name",
      subscriptionType: "subscription_plan",
      startDate: "subscription_valid_from",
      endDate: "subscription_valid_until",
      subscriptionPeriodLeft: "sub_period_left",
      status: "status",
      planId: "plan_id",
      projectId: "project_id",
      plan_amount: "plan_amount",
      download_delay: "download_delay",
      container_idle_time: "container_idle_time",
      use_uploader: "use_uploader",
      uploader_start_api: "uploader_start_api",
      uploader_data_api: "uploader_data_api",
      uploader_end_api: "uploader_end_api",
      uploader_workers: "uploader_workers",
      uploader_url_to_pdf_workers: "uploader_url_to_pdf_workers",
      api_request_timeout: "api_request_timeout",
    },
    user: {
      nickname: "nickname",
      gender: "gender",
    },
  },
  loginModule: {
    userName: "User Name",
    pwd: "Password",
    logIn: "Login",
    forgotPwd: "Forgot Password",
    required: {
      userName: "User Name Required",
      pwd: "Password Required",
    },
  },
  passwordModule: {
    password: "Password",
    repassword: "Retype Password",
  },
  userManagementModule: {
    submit: "Submit",
    editUser: "Edit User",
    email: "Email",
    code: "Code",
    pwd: "Password",
    confirmPwd: "Confirm Password",
    sendCode: "Send Code",
    resetPwd: "Reset Password",
    codehelp: "Please check your email for the code.",
    required: {
      email: "Email Required",
      code: "Verification Code Required",
      emailInvalid: "Please enter a valid email",
      pwd: "Password Required",
      confirmPwd: "Confirm Password Required",
      pwdMisMatch: "Password and Confirm Password should be same",
    },
  },
  tenantModule: {
    addTenant: "Add Tenant",
    tenantconfig: "Tenant Configuration update",
    editTenant: "Edit Tenant",
    companyName: "Company Name",
    subscriptionType: "Subscription Type",
    plan: "Plan",
    projects: "Categories",
    project: "Category",
    startDate: "Start Date",
    subscriptionPeriodLeft: "Subscription Period Left",
    plan_amount: "Plan Price ($)",
    status: "Status",
    name: "Name",
    email: "Email",
    topic: "Topic",
    domain_name: "Domain",
    planStartDate: "Plan Start Date",
    planEndDate: "Plan End Date",
    download_delay: "Download Delay (Sec)",
    container_idle_time: "Container Idle Time (Sec)",
    use_uploader: "Use Uploader",
    uploader_start_api: "Start Scan Callback",
    uploader_data_api: "Data Upload Callback",
    uploader_end_api: "End Scan Callback",
    uploader_workers: "Uploader Workers",
    uploader_url_to_pdf_workers: "Uploader URL To PDF Workers",
    api_request_timeout: "API Request Timeout (Sec)",
    requiredAddFrom: {
      name: "Name Required",
      email: "Email Required",
      emailInvalid: "Please enter a valid email",
      subType: "SubscriptionType Required",
      startDate: "Start Date Required",
      endDate: "End Date Required",
      plan: "Plan Required",
      project: "Category Required",
      download_delay: "Download Delay Required",
      container_idle_time: "Container Idle Time Required",
      use_uploader: "Use Uploader Required",
      uploader_start_api: "Start Scan API Required",
      uploader_data_api: "Data Upload API Required",
      uploader_end_api: "End Scan API Required",
      uploader_workers: "Uploader Workers Required",
      uploader_url_to_pdf_workers: "Uploader URL To Pdf Workers Required",
      api_request_timeout: "API Request Timeout Required",
    },
  },
};
