import FormValidationMessageComponent from "../Shared/Components/FormValidationMessage";
import find from "lodash/find";

export const constats = {
  DATE_FORMAT_ddmmyy: "dd-mm-yy",
  DATE_FORMAT_backslash_ddmmyyyy: "dd/mm/yyyy",
  DATE_FORMAT_primereact_calendar_ddmmyy: "dd/mm/yy",
  DATE_FORMAT_READABLE: "dd M YY",
};
export const userRoles = {
  SuperAdmin: "super_admin",
  TenantAdmin: "tenant_admin",
};

export const validateEmail = (email) => {
  const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/;
  return re.test(String(email).toLowerCase());
};

export const showFormValidation = (isShow, isError, msg) => {
  return <FormValidationMessageComponent data={{ isShow, isError, msg }} />;
};
export const stringToDate = (date, format = "dd/mm/yyyy", delimiter = "/") => {
  var formatLowerCase = format.toLowerCase();
  var formatItems = formatLowerCase.split(delimiter);
  var dateItems = date.split(delimiter);
  var monthIndex = formatItems.indexOf("mm");
  var dayIndex = formatItems.indexOf("dd");
  var yearIndex = formatItems.indexOf("yyyy");
  var month = parseInt(dateItems[monthIndex]);
  month -= 1;
  var formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
  return formatedDate;
};
export const dateDiff = (date1, date2, interval) => {
  let second = 1000,
    minute = second * 60,
    hour = minute * 60,
    day = hour * 24,
    week = day * 7;
  date1 = new Date(date1);
  date2 = new Date(date2);
  let timediff = date2 - date1;
  let diffVal = "";
  if (!isNaN(timediff)) {
    switch (interval) {
      case "years":
        diffVal = date2.getFullYear() - date1.getFullYear();
        break;
      case "months":
        diffVal =
          date2.getFullYear() * 12 +
          date2.getMonth() -
          (date1.getFullYear() * 12 + date1.getMonth());
        break;
      case "weeks":
        diffVal = Math.floor(timediff / week);
        break;
      case "days":
        diffVal = Math.floor(timediff / day);
        break;
      case "hours":
        diffVal = Math.floor(timediff / hour);
        break;
      case "minutes":
        diffVal = Math.floor(timediff / minute);
        break;
      case "seconds":
        diffVal = Math.floor(timediff / second);
        break;
      default:
    }
  }
  return diffVal;
};
export const formatDate = (
  date,
  format = constats.DATE_FORMAT_backslash_ddmmyyyy
) => {
  const newDate = date instanceof Date ? date : new Date(date);
  let formatedDate = "";
  switch (format) {
    case constats.DATE_FORMAT_backslash_ddmmyyyy:
      //British English uses day-month-year order
      formatedDate = newDate.toLocaleDateString("en-GB");
      break;
    case constats.DATE_FORMAT_READABLE:
      const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
        newDate
      );
      const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(
        newDate
      );
      const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
        newDate
      );
      formatedDate = `${da} ${mo} ${ye}`;
      break;
    default:
      break;
  }
  return formatedDate;
};

export const titleCase = (str = "") => {
  var splitStr = str.replace(/_/g, " ").split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};

export const cloneArray = (array) => {
  if (!Array.isArray(array)) return [];
  return [].concat(array);
};

const renameFields = function (xs) {
  var c = {},
    t = (x, n) => x + "_" + n;

  return xs.map(function (x) {
    var n = c[x.fieldName] || 0;

    c[x.fieldName] = n + 1;

    if (!n) return x;

    while (c[t(x.fieldName, n)]) n++;

    c[t(x.fieldName, n)] = 1;
    return { ...x, fieldName: t(x.fieldName, n) };
  });
};

export const templateToJson = (_template = []) => {
  let json = {};
  _template.forEach((innerObject) => {
    if (innerObject.checked) {
      if (innerObject.type === "object") {
        if (innerObject.fieldName) {
          if (innerObject.parentField) {
            json[innerObject.parentField] = {
              ...(json[innerObject.parentField] || {}),
              [innerObject.fieldName]: templateToJson(innerObject.properties),
            };
          } else {
            json[innerObject.fieldName] = templateToJson(
              innerObject.properties
            );
          }
        } else {
          json = { ...json, ...templateToJson(innerObject.properties) };
        }
      } else if (innerObject.type === "key-value") {
        let properties = renameFields(innerObject.properties);
        if (innerObject.parentField) {
          json = {
            ...json,
            [innerObject.parentField]: {
              ...(json[innerObject.parentField] || {}),
              [innerObject.fieldName]: templateToJson(properties),
            },
          };
        } else {
          json = {
            ...json,
            [innerObject.fieldName]: templateToJson(properties),
          };
        }
      } else if (innerObject.type === "array") {
        if (innerObject.parentField) {
          json[innerObject.parentField] = {
            ...(json[innerObject.parentField] || {}),
            [innerObject.fieldName]: [templateToJson(innerObject.properties)],
          };
        } else {
          json[innerObject.fieldName] = [
            templateToJson(innerObject.properties),
          ];
        }
      } else if (innerObject.type === "list") {
        if (innerObject.parentField) {
          json[innerObject.parentField] = {
            ...(json[innerObject.parentField] || {}),
            [innerObject.fieldName]: innerObject.value
              ? innerObject.value.split("||")
              : [],
          };
        } else {
          json[innerObject.fieldName] = innerObject.value
            ? innerObject.value.split("||")
            : [];
        }
      } else if (innerObject.type === "boolean") {
        if (innerObject.parentField) {
          json[innerObject.parentField] = {
            ...(json[innerObject.parentField] || {}),
            [innerObject.fieldName]: innerObject.value || false,
          };
        } else {
          json[innerObject.fieldName] = innerObject.value || false;
        }
      } else if (innerObject.type === "number") {
        if (innerObject.parentField) {
          json[innerObject.parentField] = {
            ...(json[innerObject.parentField] || {}),
            [innerObject.fieldName]: innerObject.value || null,
          };
        } else {
          json[innerObject.fieldName] = innerObject.value || null;
        }
      } else {
        if (innerObject.parentField) {
          json[innerObject.parentField] = {
            ...(json[innerObject.parentField] || {}),
            [innerObject.fieldName]: innerObject.value || "",
          };
        } else {
          json[innerObject.fieldName] = innerObject.value || "";
        }
      }
    }
  });
  return json;
};

export const attrTemplateToJSON = (_template = [], json = {}) => {
  json["path_to_elements"] = { description: {}, date: {}, text: {}, files: {} };
  let xPath = find(
    _template,
    (obj) => obj.title === "Data Xpath Configurations"
  );
  let attrVales =
    xPath &&
    find(xPath.properties, (obj) => obj.fieldName === "path_to_elements");
  if (attrVales) {
    attrVales.properties.forEach((innerObj) => {
      if (innerObj.attributeType) {
        json[innerObj.parentField] = {
          ...json[innerObj.parentField],
          [innerObj.attributeType]: {
            ...json[innerObj.parentField][innerObj.attributeType],
            [innerObj.fieldName]: innerObj.value || "",
          },
        };
      }
    });
  }
  return json;
};

export const validateTemplate = (_template, original_template) => {
  let isValid = [];
  _template.forEach((innerObject) => {
    if (innerObject.checked) {
      if (
        innerObject.condition &&
        !find(original_template[0].properties, innerObject.condition)
      ) {
        return;
      }
      if (
        innerObject.type === "object" ||
        innerObject.type === "key-value" ||
        innerObject.type === "array"
      ) {
        isValid.push(
          ...validateTemplate(innerObject.properties, original_template)
        );
      } else {
        if (innerObject.mandatory) {
          isValid.push(innerObject.value ? true : false);
        }
      }
    }
  });
  return isValid;
};

export const underscoreToSpace = (str = "") => {
  return str.replace(/_/g, " ");
};

export const SpaceToUnderscore = (str = "") => {
  return str.replace(/ /g, "_");
};

export const DecimalToTime = (decimal = 0.0) => {
  var parts = decimal.toString().split(".");
  let deci = parts[1] ? parseInt((parts[1] * 60) / 100) : null;
  //getting whole number portion
  if (deci) {
    return `${parts[0]} Mins and ${deci} Sec`;
  } else {
    return `${parts[0]} Mins`;
  }
};

export const generateRandomColors = (limit = 1, defaultColor = "#000080") => {
  let colors = [
    "#EC407A",
    "#AB47BC",
    "#42A5F5",
    "#7E57C2",
    "#66BB6A",
    "#FFCA28",
    "#26A69A",
    "#FFA726",
    "#26C6DA",
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
  ];
  let color = "";
  let colorArray = [];
  for (let i = 0; i < limit; i++) {
    color = colors[i];
    if (i > colors.length) {
      color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    }
    colorArray.push(color);
  }
  colorArray.push(defaultColor);
  return colorArray;
};
export const secondsToHHMMSS = (sec, isMinute = false) => {
  if (isMinute) {
    let parts = sec.toString().split(".");
    let secs = parts[1] ? parseInt((parts[1] * 60) / 100) : 0;
    sec = parts[0] * 60 + secs;
  }
  var sec_num = parseInt(sec, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return {
    hours: hours.toString(),
    minutes: minutes.toString(),
    seconds: seconds.toString(),
  };
};
