import React from "react";
import { Router, Switch } from "react-router-dom";
import { GlobalStateProvider } from "./Context";
import AppRoute from "./Core/AppRoute";
import routes from "./Core/Routes";
import history from "./Core/History";
import { Toast } from "primereact/toast";

function App() {
  const myToast = React.useRef(null);
  const showToast = (severityValue, summaryValue, detailValue, life = 3000) => {
    myToast.current.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
      life: life,
    });
  };

  return (
    <GlobalStateProvider>
      <Router history={history}>
        <Switch>
          {routes.map((route) => {
            return (
              <AppRoute
                key={route.path}
                component={route.component}
                path={route.path}
                isPrivate={route.isPrivate}
                permissions={route.permissions}
                showToast={showToast}
              />
            );
          })}
        </Switch>
      </Router>
      <Toast ref={myToast} position="top-right" />
    </GlobalStateProvider>
  );
}

export default App;
