import React, { Component } from "react";

//import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
// import {require} from 'browserify';

import InputTextWrapper from "../../Shared/Components/InputText";
import WithLayout from "../../Shared/Components/HeaderFooterHOC";
//import linkIcon from "../../Assets/images/link-icon.png";
import StoryOption from "./StoryOption";
import { Checkbox } from 'primereact/checkbox';

import { showFormValidation } from "../../Utilities/Utils";
// import { TEXT_LABELS } from "../../Utilities/TextLabels";

import { listStoryByTopicId, getStory } from "../../Services/CommonServices";
import { fileUpload } from "../../Services/CommonServices";
import { createStory, updateStory, updateTopic } from "../../Services/CommonServices";
import loader from "../../Assets/images/loader-1.png";
import ReactPlayer from 'react-player';
// import DialogBox from '../../Shared/Components/DialogBox';


class Story extends Component {
  constructor() {
    super();
    this.state = {
      topicId: '',
      topic_name: '',
      file: "",
      file_upload: "",
      stories: [],
      st_name: "",
      st_question: "",
      st_type: "",
      submitted: false,
      optionChecked: false,
      showLoader: false,
      editStory: false,
      editStory_id: '',
      editButtonClicked: false,
      firstStory_id: '',
      deleteModal: false,
      // deleteStoryError: false,
      // deleteStoryErrorDetails: [],
      firstStory: [],
      noStories: false,
      checked: false,
      topicType: '',
      quadrant: '',
      quadrantColor: '',
      optionDetails: [
        {
          index: Math.random(),
          story_name: "",
          story_option: "",
        },
      ],
      topicDeatils: {}
    };
    // listStory().then((story) => {
    //   story.data.map((st) => {
    //     this.state.stories.push({ id: st.id, story_name: st.story_name });
    //   });
    //   this.setState({ stories: this.state.stories });
    // });
  }

  componentDidMount() {
    const topicId = this.props.match.params.id;
    this.setState({ showLoader: true, topicId});

    listStoryByTopicId(this.props.match.params.id)
      .then((res) => {
        this.setState({ ...this.state, topicDeatils: res.data.topic });
        if (res.data.stories.length > 0) {
         
          this.setState({ stories: res.data.stories, noStories: false, showLoader: false, topic_name: res.data.topic.topic_name });;
          // const storyId = res.data.topic.story_id;
          // if (storyId) {
          //   const x = res.data.stories.filter(item => item.id === storyId);
          //   this.selectedStory(x[0]);
          // } else {
          //   this.selectedStory(res.data.stories[0])
          // }
          this.selectedStory(res.data.stories[0])
        } else {
          this.setState({ stories: [], noStories: true, showLoader: false, topic_name: res.data.topic.topic_name })
        }
      })
      .catch(err => {
        console.log(err.response);

      });
  }

  // options enable or disable
  changeOption = (e) => {
    e.preventDefault();
    if (this.state.optionChecked) {
      this.setState({ optionChecked: false });
    } else {
      this.setState({ optionChecked: true });
    }
  };

  // setting options input values
  onChangeOptions = (index, e) => {
    let newArr = [...this.state.optionDetails];
    newArr[index][e.target.name] = e.target.value;
    this.setState({ newArr });
  }

  // setting input values
  handleChange = (e) => {
    e.preventDefault();
    if (e.target.name === "st_name") {

      this.setState({ st_name: e.target.value });

    }
    if (e.target.name === "st_question") {

      this.setState({ st_question: e.target.value });

    }
    if (["story_name", "story_option"].includes(e.target.name)) {

      let optionDetails = [...this.state.optionDetails];
      optionDetails[e.target.dataset.id][e.target.name] = {S:e.target.value};

    } else {

      this.setState({ [e.target.name]: e.target.value });

    }
  };

  
  // add new story click
  // addNewStoryForm = (e) => {
  //   e.preventDefault();
  //   this.setState({
  //     ...this.state, showLoader: true, editStory: false, noStories: false, checked: false
  //   })
  //   setTimeout(() => {
  //     this.resetForm(e);
  //   }, 500);
  // }


  // add new story
  // onStorySubmit = (e) => {

  //   e.preventDefault();
  //   this.setState({ submitted: true });

  //   let topicFormData = this.state.topicDeatils;

  //   const formData = {
  //     id: this.state.topicId,
  //     story_name: this.state.st_name,
  //     content_type: this.state.st_type,
  //     content: this.state.st_question,
  //     file_url: this.state.file,
  //   };

  //   if (this.state.optionChecked) {

  //     var filteredArray = this.state.optionDetails.filter(function (el) {
  //       return el.story_name != '';
  //     });

  //     formData.options = filteredArray.map((st) => {

  //       return {
  //         content: st.story_name,
  //         name: st.story_name,
  //         link: st.story_option,
  //         type: "text",
  //       };
  //     })
  //   } else {
  //     formData.options = []
  //   }

  //   if (!formData.story_name || !formData.content) {
  //     return
  //   } else {

  //     this.setState({ submitted: true });
  //     createStory(formData)
  //       .then((res) => {

  //         const storyId = res.data.id;

  //         if (this.state.checked) {
  //           topicFormData.story_id = storyId;

  //           updateTopic(this.props.match.params.id, topicFormData)
  //             .then(res => {
  //               // console.log(res);
  //             })
  //             .catch(err => {
  //               console.log(err);
  //             })
  //         }
  //         listStoryByTopicId(this.props.match.params.id)
  //           .then((res) => {

  //             this.setState({ stories: res.data.stories });

  //             let story = res.data.stories.find(item => item.id === storyId);
  //             this.selectedStory(story);
  //           })


  //         this.resetForm(e);
  //         this.setState({ showLoader: false });
  //         this.props.showToast("success", "Success", "Story saved");
  //       })
  //       .catch(err => {
  //         this.setState({ showLoader: false });
  //         this.props.showToast("error", "Error", "Error saving story");
  //       })
  //   }
  // };

  // select a story
  selectedStory = (item) => {
    this.editItem(item);
  }

  // append data to fields
  editItem = (item, quadrantColor) => {

    this.setState({ showLoader: true, quadrant: item.quadrant, quadrantColor: quadrantColor});
    if(quadrantColor != undefined){
      this.setState({ quadrantColor: quadrantColor})
    }else{
      this.setState({ quadrantColor: 'primary'})
    }

    const topicId = this.props.match.params.id;

    getStory(item.id, topicId)
      .then(res => {
        // console.log(res.data)
        this.setState({
          editStory: true,
          editStory_id: item.id,
          st_name: res.data.story_name,
          st_question: res.data.content,
          file: res.data.file_url,
          st_type: res.data.content_type
        });
        // if (item.id === this.state.topicDeatils.story_id) {
        //   this.setState({ ...this.state, checked: true })
        // } else {
        //   this.setState({ ...this.state, checked: false });
        // }
        if ( res?.data?.options && JSON.parse( res?.data?.options).length > 0) {
          const arr = [];
          
       JSON.parse( res.data.options)?.map(item => {
       
            arr.push({ story_name:Object.values(item)[0].name, story_option: Object.values(item)[0].link})
          })
          this.setState({
            optionChecked: true,
            optionDetails: [...arr]
          })
        } else {
          this.setState({
            optionChecked: false,
            optionDetails: [
              {
                index: Math.random(),
                story_name: "",
                story_option: "",
              },
            ]
          })
        }
        this.setState({ showLoader: false })

      })
      .catch(err => {
        console.log(err);
        this.setState({ showLoader: false });
        this.props.showToast("error", "Error", "Error fetching story");
      })
    // resetForm
  }

  // update story
  updateStory = (e) => {

    e.preventDefault();

    const id = this.state.editStory_id;
    this.setState({ submitted: true });

    // let topicFormData = this.state.topicDeatils;

    // if (this.state.checked) {

    //   topicFormData.story_id = id;

    //   updateTopic(this.props.match.params.id, topicFormData)
    //     .then(res => {
    //       // console.log(res);
    //     })
    //     .catch(err => {
    //       console.log(err);
    //     })
    // } else {

    //   if (topicFormData.story_id === id) {
    //     topicFormData.story_id = '';
    //     updateTopic(this.props.match.params.id, topicFormData)
    //       .then(res => {
    //         // console.log(res);
    //       })
    //       .catch(err => {
    //         console.log(err);
    //       })
    //   }
    // }

    const formData = {
      story_name: this.state.st_name,
      content_type: this.state.st_type,
      content: this.state.st_question,
      file_url: this.state.file,
      // id: this.state.topicId
      id: this.props.match.params.id
    };

    if (this.state.optionChecked) {

      var filteredArray = this.state.optionDetails.filter(function (el) {
        return el.story_name !== '';
      });

      formData.options = filteredArray.map((st) => {
        return {
          content: st.story_name,
          name: st.story_name,
          link: st.story_option,
          type: "text",
        };
      })
    } else {
      formData.options = []
    }

    if (!formData.story_name || !formData.content) {

      return

    } else {

      this.setState({ submitted: true });
      updateStory(formData, id)
        .then((res) => {
          listStoryByTopicId(this.props.match.params.id)
            .then((res) => {
              this.setState({ stories: res.data.stories });
              let story = res.data.stories.find(item => item.id === id);
              this.selectedStory(story);

            })
          this.resetForm(e);
          this.props.showToast("success", "Success", "Story updated");

        })
        .catch(err => {
          console.log(err)
          this.setState({ showLoader: false });
          this.props.showToast("error", "Error", "Error updating story");
        });
    }
  }

  // delete modal enable
  // deleteModal = (e) => {
  //   e.preventDefault();
  //   this.setState({ deleteModal: true });
  // }

  // delete a story
  // deleteStory = async (e) => {

  //   e.preventDefault();
  //   const storyId = this.state.editStory_id;
  //   const topicId = this.state.topicId
  //   this.setState({ showLoader: true, deleteModal: false });

  //   if (this.state.topicDeatils.story_id === storyId) {
  //     let topicFormData = this.state.topicDeatils;
  //     topicFormData.story_id = '';
  //     await updateTopic(this.props.match.params.id, topicFormData)
  //       .then(res => {
  //         // console.log(res);
  //       })
  //       .catch(err => {
  //         console.log(err);
  //       })
  //   }

  //   deleteStory(storyId, topicId)
  //     .then((res) => {

  //       listStoryByTopicId(this.props.match.params.id)
  //         .then((res) => {

  //           this.setState({ ...this.state, topicDeatils: res.data.topic });

  //           if (res.data.stories.length > 0) {

  //             this.setState({ stories: res.data.stories, noStories: false, showLoader: false, topic_name: res.data.topic.topic_name });
  //             const story_Id = res.data.topic.story_id;

  //             if (story_Id) {
  //               const x = res.data.stories.filter(item => item.id === story_Id);
  //               this.selectedStory(x[0]);

  //             } else {

  //               this.selectedStory(res.data.stories[0])

  //             }

  //           } else {
  //             this.setState({ stories: [], noStories: true, showLoader: false, topic_name: res.data.topic.topic_name })
  //           }
  //         })
  //         .catch(error => {
  //           console.log(error);

  //         });

  //       // this.componentDidMount();
  //       this.resetForm(e);
  //       this.setState({ showLoader: false });
  //       this.props.showToast("success", "Success", "Story deleted");
  //     })
  //     .catch((err => {
  //       let data = err.response.data;
  //       console.log()
  //       this.setState({ showLoader: false, deleteStoryError: true, deleteStoryErrorDetails: data });
  //       // this.props.showToast("error", "Error", `${err.response.data.message}. Linked stories are ${links.map(item=>( item.name))}` );
  //     }))

  // }


  // deleteStoryErrorDialogFooter = () => {
  //   return (
  //     <React.Fragment>
  //       <Button
  //         label="Ok"
  //         icon="pi pi-check"
  //         className="p-button-primary"
  //         onClick={() => this.setState({ deleteStoryError: false, deleteStoryErrorDetails: [] })}
  //       />
  //     </React.Fragment>
  //   );
  // };

  // cancel add new story mode
  // cancelClick = (e) => {
  //   e.preventDefault();
  //   this.setState({ ...this.state })
  //   this.componentDidMount();
  // }

  resetForm = (e) => {
    e.preventDefault()
    this.setState({
      st_name: '',
      st_type: "text",
      st_question: '',
      file: null,
      submitted: false,
      editStory: false,
      editStory_id: '',
      showLoader: false,
      optionChecked: false,
      optionDetails: [
        {
          index: "",
          story_name: "",
          story_option: "",
        }]
    })
  }

  // story option add new row
  addNewRow = (e) => {
    e.preventDefault()
    this.setState((prevState) => ({
      optionDetails: [
        ...prevState.optionDetails,
        {
          index: Math.random(),
          story_name: "",
          story_option: "",
        },
      ],
    }));
  };

  // remove story option row
  deteteRow = (index) => {
    this.setState({
      optionDetails: this.state.optionDetails.filter(
        (s, sindex) => index !== sindex
      ),
    });
  };

  clickOnDelete(e, record) {
    e.preventDefault();
    this.setState({
      optionDetails: this.state.optionDetails.filter((r) => r !== record),
    });
  }

  // clear first option field
  clearOptions(e) {
    e.preventDefault();
    this.setState({
      ...this.state, optionDetails: [
        {
          index: Math.random(),
          story_name: "",
          story_option: "",
        },
      ],
    })
  }

  // select a file 
  fileChanged = async (e) => {

    e.preventDefault();
    if (e.target.files.length === 0) {
      return;
    }
    this.setState({ showLoader: true });

    if (e.target.files[0].size > 9999999) {
      this.props.showToast("error", "Error", "Maximum file size 10MB");
      this.setState({ showLoader: false });
      return;
    }

    let encodedString;
    await this.getBase64(e.target.files[0])
      .then(res => {
        encodedString = res;
      })
      .catch(error => {
        this.props.showToast("error", "Error", "Something went wrong. Try again !");
        this.setState({ showLoader: false })
      })

    const body = {
      "file_name": e.target.files[0].name,
      "type": "story_file",
      "base64_string": encodedString
    }

    const file_type = e.target.files[0].type.split('/')[0];
    fileUpload(body)
      .then((flp) => {
        this.setState({ showLoader: false })

        if (flp.data.url)
          this.setState({
            file: flp.data.url.replace('ap-south-1','us-east-1'),
          });
        this.setState({ st_type: file_type });
      })
      .catch(err => {
        console.log(err);
        if (err.request) { console.log(err.request) } if (err.response) { console.log(err.response) }
      });
  };

  // convert file to base64
  getBase64 = (file) => {

    return new Promise((resolve, reject) => {

      let baseURL = "";

      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result.replace("data:", "").replace(/^.+,/, "");

        if (baseURL) {
          resolve(baseURL);
        } else {
          reject("error")
        }

      };
    });
  };

  // remove image or video
  removeImage = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      file: "",
      st_type: "text",
      file_upload: ""
    });
  };


  reDirect = (e) => {
    e.preventDefault();
    this.props.history.push('/topics');
  }

  render() {

  // Capitalize
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

    return (
      <>
        <div
          className="loader-overlay"
          style={{ display: this.state.showLoader ? "flex" : "none" }}
        >
          <div className="p-d-flex p-flex-column p-ai-center">
            <div className="loader">
              <img src={loader} alt="loader" width="100" height="100" />
            </div>
            <span className="loading-text p-mt-2">
              Loading... Please Wait...
            </span>
          </div>
        </div>

        {/* <div>
          {this.state.deleteModal && (
            <div>
              <DialogBox area="story" type="delete" showDialog={this.state.deleteModal} 
                          hideDialog={()=>this.setState({ deleteModal: false })} product={this.state}
                          confirmClick={(e) => this.deleteStory(e)}/>
            </div>
          )}
        </div> */}

        {/* <div>
          {this.state.deleteStoryError && (
            <div>
              <Dialog
                visible={this.state.deleteStoryError}
                style={{ width: "450px" }}
                header="Warning"
                modal
                footer={this.deleteStoryErrorDialogFooter}
                onHide={() => this.setState({ deleteStoryError: false, deleteStoryErrorDetails: [] })}
              >
                <div className="confirmation-content d-flex align-items-center">
                  <i
                    className="pi pi-exclamation-triangle p-mr-3"
                    style={{ fontSize: "2rem" }}
                  />
                  {
                    <span>
                      {this.state.deleteStoryErrorDetails.message}.
                      <br />
                      Linked stories are
                      <ul>
                        {
                          this.state.deleteStoryErrorDetails.links.map(item => (
                            <li key={item.id} style={{ cursor: 'pointer' }}
                              onClick={() => { this.editItem(item); this.setState({ deleteStoryError: false, deleteStoryErrorDetails: [] }) }}>{item.name}</li>
                          ))
                        }
                      </ul>
                    </span>

                  }
                </div>
              </Dialog>
            </div>
          )}
        </div> */}

        <div className="h-100">
          <div className="main-heading-wrapper d-flex align-items-center justify-content-between mb-2">
            <div className="d-flex align-items-center">
              <button className="btn btn-clear me-2" onClick={(e) => this.reDirect(e)}> <span className="icon icon-arrow_back"></span>
              </button>
              <h4 className="main-heading mb-0">{this.state.topic_name}</h4>
              {
                this.state.topicDeatils.task_name === 'conversation'
                  ? <span className="heading-badge badge bg-primary  ms-2 d-inline-block">Conversation</span>
                  : <span className="heading-badge badge bg-primary ms-2 d-inline-block">Experience</span>
              }
            </div>
          </div>
          
          <div className="main-page-content">
            <div className="cust-card h-100">
              <div className="row h-100">

                <div className="col-4 h-100 pe-0">
                  <div className="cust-card cust-card-left without-shadow h-100">
                    <div className="cust-card-header p-d-flex p-ai-center">
                      <div className="p-d-flex p-ai-center p-jc-between w-100">
                        <h3 className="cust-card-title mb-0">Stories</h3>
                        {/* <div>
                          {
                            this.state.editStory ?
                              <button className="p-button p-component p-button-rounded p-button-primary p-button-icon-only" title="Add New Story" onClick={(e) => this.addNewStoryForm(e)}>
                                <i className='pi pi-plus'></i>
                              </button>

                              : null
                          }
                        </div> */}
                      </div>
                    </div>
                    <div className="cust-card-content p-p-0">
                      <ul className="stories-list">
                        {/* {
                          !this.state.editStory ? <li className="active">Add a new story</li> : null
                        } */}
                        {
                          this.state.stories.length > 0 ?
                            this.state.stories.map((item, index) => {
                              let quadrantColor = ['primary', 'secondary', 'success', 'danger'];
                              return (
                                <div key={index} className={this.state.editStory ? '' : 'disableLi'}>
                                  <li className={`${this.state.editStory_id === item.id ? 'active ' : ''}`} key={item.id} onClick={() => this.editItem(item, quadrantColor[index])}>
                                    <div className="d-flex align-items-center storyname">
                                      <span className="me-2">{index + 1}. </span>
                                      <span>{item.story_name}</span>
                                    </div>
                                    {/* {
                                      this.state.topicDeatils.story_id === item.id ?
                                        <span className="conversation-start-qn-text">
                                          Initial Story
                                        </span>
                                        : null
                                    } */}
                                   <span className={`heading-badge badge bg-${quadrantColor[index]} ms-2 d-inline-block`}>{capitalizeFirstLetter(item.quadrant)}</span>
                                  </li>
                                </div>
                              );
                            }) : null
                        }
                        {/* <li>
                        <div className="d-flex align-items-center">
                          <span> </span>Story
                          <span>23232</span>
                        </div>
                        <div className="story-link-status"> -
                      </li>
                      <li className="active">
                        <div className="d-flex align-items-center">topics
                          <span>Story 2</span>
                        </div>
                        <div className="story-link-status story-link-completed">
                          <span className="icon icon-check"></span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center">
                          <span>3. </span>
                          <span>Story 3</span>
                        </div>
                        <div className="story-link-status story-link-completed">
                          <span className="icon icon-check"></span>
                        </div>
                      </li> */}
                      </ul>
                    </div>

                    {/* <div className="cust-card-footer p-d-flex p-ai-center px-3">
                        <div className="end-chat d-flex justify-content-between w-100 align-items-center">
                          Voice Section
                            <i className="pi pi-volume-down"></i>
                        </div>
                      </div> */}
                  </div>
                </div>

                <div className="col-8 h-100 ps-0">
                  <form
                    className="form-signin h-100"
                    onChange={(e) => this.handleChange(e)}
                  // onSubmit={(e) => this.onStorySubmit(e)}
                  >
                    <div className="cust-card without-shadow cust-card-right h-100">
                      <div className="cust-card-header">
                        <div className="p-d-flex p-ai-center p-jc-between">
                          {
                            // !this.state.editStory ? <h3 className="cust-card-title mb-0">Add a new story</h3> : <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center">
                              <h3 className="cust-card-title mb-0">Story Editor </h3>
                              {/* {
                                this.state.topicDeatils.task_name === 'conversation' && this.state.topicDeatils.story_id === this.state.editStory_id
                                  ? <span className="conersation-initial-story"></span>
                                  : ''
                              } */}

                            </div>
                          }
                          <div className="btn-wrapper">
                            {/* {
                              !this.state.editStory && !this.state.noStories ?
                                <button className="p-button p-component p-button-rounded p-button-secondary p-button-icon-only me-2" title="cancel" onClick={(e) => this.cancelClick(e)}>
                                  <i className='pi pi-times'></i>
                                </button> : null
                              // <button className="btn btn-info me-2" onClick={(e) => this.editClick(e)}>
                              //      Edit
                              // </button>
                            } */}
                            {/* {
                              !this.state.editStory ? <button className="p-button p-component p-button-rounded p-button-secondary p-button-icon-only me-2" title="clear" onClick={(e) => this.resetForm(e)}>
                                <i className='pi pi-refresh'></i>
                              </button> : null

                            } */}
                            {/* <button className="btn btn-info me-2" onClick={(e) => this.resetForm(e)}>Clear</button> */}
                            {/* {
                              this.state.editStory ?
                                <button className="p-button p-component p-button-rounded p-button-danger p-button-icon-only me-2" title="Delete" onClick={(e) => this.deleteModal(e)}>
                                  <i className="pi pi-trash"></i>
                                </button> : null
                            } */}
                            {/* {
                              this.state.editStory ?
                                // <button className="btn btn-save" onClick={(e) => this.updateStory(e)}>
                                //   Delete
                                // </button> &&
                                <button className="p-button p-component p-button-rounded p-button-success p-button-icon-only" title="Update" onClick={(e) => this.updateStory(e)}>
                                  <i className="pi pi-save"></i>
                                </button> :
                                <button className="p-button p-component p-button-rounded p-button-success p-button-icon-only" title="Save" onClick={(e) => this.onStorySubmit(e)}>
                                  <i className="pi pi-save"></i>
                                </button>
                            } */}

                                <button className="p-button p-component p-button-rounded p-button-success p-button-icon-only" title="Save" onClick={(e) => this.updateStory(e)}>
                                  <i className="pi pi-save"></i>
                                </button>
                          </div>
                        </div>
                      </div>
                      <div className="cust-card-content">
                        {/* <div className="row mb-2">
                        <div className="col-md-12">
                          <div className="d-flex align-items-center">
                            <span className="fw-medium me-2">Linked from</span>
                            <div className="linked-stories">
                              <ul>
                                <li>Story 1</li>
                                <li>Story 2</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div> */}

                        <div className="row">
                          <div className="col-xl-12 col-lg-12">
                            <div className="row mb-2 align-items-center">
                              <div className="col-7 col-xxl-5">
                                <div className="form-floating">
                                  <InputTextWrapper
                                    id="st_name"
                                    name="st_name"
                                    type="text"
                                    required
                                    className="form-control"
                                    // disabled={this.state.editButtonClicked ? false : true}
                                    autoComplete="off"
                                    placeholder="test"
                                    value={this.state.st_name}
                                    onChange={(e) => this.handleChange(e)}
                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault() }}
                                  />
                                  <label htmlFor="story_name">Story Name</label>

                                </div>
                              </div>
                              {this.state.submitted && !this.state.st_name
                                ? showFormValidation(
                                  true,
                                  true,
                                  'Story name required'
                                )
                                : showFormValidation(false)}
                              <div className="col-5">
                                {/* <div className="p-field-checkbox mb-0">
                                  <Checkbox inputId="cb1" checked={this.state.checked} onChange={e => this.setState({ ...this.state, checked: e.checked })}></Checkbox>
                                  <label htmlFor="cb1" >Initial story</label>
                                </div> */}
                                <span className={`heading-badge badge bg-${this.state.quadrantColor} ms-2 d-inline-block`}>{capitalizeFirstLetter(this.state.quadrant)}</span>
                              </div>
                            </div>

                            <div className="row mb-2">
                              <div className="col-7 col-xxl-5">
                                <div className="qn-with-media">
                                  <div className="form-floating">
                                    <textarea
                                      className="form-control text-area-height"
                                      placeholder="Leave a comment here"
                                      id="st_question"
                                      autoComplete="off"
                                      name="st_question"
                                      // disabled={this.state.editButtonClicked ? false : true}
                                      value={this.state.st_question}
                                      onChange={(e) => this.handleChange(e)}
                                    >
                                      {/* {this.state.st_question} */}
                                    </textarea>
                                    <label htmlFor="floatingTextarea2">
                                      Question
                                    </label>
                                  </div>

                                  <div className="include-media d-flex align-items-center">
                                    <span className="me-2">
                                      Include Media
                                    </span>
                                    <ul className="m-0 p-0">
                                      <li>
                                        <label
                                          className="media"
                                          htmlFor="file_upload_id"
                                        >
                                          <span className="icon icon-image"></span>
                                        </label>
                                        <input
                                          // disabled={this.state.editButtonClicked ? false : true}
                                          id="file_upload_id"
                                          type="file"
                                          name="file_upload"
                                          value={this.state.file_upload}
                                          onChange={(e) => this.fileChanged(e)}
                                        />
                                      </li>
                                    </ul>
                                    <div className="p-ml-2">
                                      {showFormValidation(true, true, '*Max size should be 10MB')}
                                    </div>
                                  </div>

                                </div>

                                <style jsx='true'>
                                  {
                                    `
                                      .audio-player {
                                          width: 100%;
                                          height: auto
                                      }
                                      .audio-player > div:first-child{
                                          width: 350px !important; 
                                          height: 75px !important
                                      }
                                    `
                                  }
                                </style>

                              </div>
                              {
                                this.state.st_type == "audio" ? 
                                  <div className="col-5">
                                    <div className="media-preview audio-player d-flex align-items-center justify-content-center p-2">
                                      <ReactPlayer url={this.state.file} controls={true} />
                                      {
                                        this.state.file ? <div className="remove-media">
                                          <span
                                            className="icon icon-close"
                                            onClick={this.removeImage}
                                          ></span>
                                        </div> : null
                                      }
                                    </div>
                                </div>
                                  : null
                                }

                              {
                                this.state.st_type == "image" ?
                                  <div className="col-3">
                                    <div className="media-preview d-flex align-items-center justify-content-center p-2" style={{ backgroundImage: `url("${this.state.file}")` }}>
                                      {
                                        this.state.file ? <div className="remove-media">
                                          <span
                                            className="icon icon-close"
                                            onClick={this.removeImage}
                                          ></span>
                                        </div> : null
                                      }

                                    </div>
                                  </div>

                                  : this.state.st_type == "video" ?
                                    <div className="col-5">
                                      <div className="media-preview video-player d-flex align-items-center justify-content-center p-2">
                                        <ReactPlayer style={{ width: '350px', height: '200px' }} url={this.state.file} controls={true} />
                                        {
                                          this.state.file ? <div className="remove-media">
                                            <span
                                              className="icon icon-close"
                                              onClick={this.removeImage}
                                            ></span>
                                          </div> : null
                                        }
                                      </div>

                                    </div>
                                    : null

                              }
                              {/* <div className="media-preview d-flex align-items-center justify-content-center p-2" style={{ backgroundImage: `url("${this.state.file}")` }}>                                  
                                  {
                                    this.state.file ? <div className="remove-media">
                                      <span
                                        className="icon icon-close"
                                        onClick={this.removeImage}
                                      ></span>
                                    </div> : null
                                  }

                                </div> */}
                              {this.state.submitted && !this.state.st_question
                                ? showFormValidation(
                                  true,
                                  true,
                                  'Story question is required'
                                )
                                : showFormValidation(false)}
                            </div>


                            <div className="row mt-2">
                              <div className="col-md-12 col-xl-10 col-xxl-8">
                                <div className="d-flex align-items-center">
                                  <label
                                    className={
                                      this.state.optionChecked
                                        ? "switch me-2 active"
                                        : "switch me-2"
                                    }
                                    onClick={(e) => this.changeOption(e)}
                                  >
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                  </label>
                                  <span>Add Options</span>
                                </div>

                                <StoryOption
                                  add={(e) => this.addNewRow(e)}
                                  // editStory = {this.state.editButtonClicked}
                                  stories={this.state.stories}
                                  topicId={this.props.match.params.id}
                                  toggleOption={this.state.optionChecked}
                                  delete={this.clickOnDelete.bind(this)}
                                  clearInput={this.clearOptions.bind(this)}
                                  optionDetails={this.state.optionDetails}
                                  onChange={(index, e) => this.onChangeOptions(index, e)}
                                />
                                {this.state.submitted && !this.state.optionDetails[0].story_name
                                    ? showFormValidation(
                                      true,
                                      true,
                                      'Atleast 1 option required'
                                    )
                                    : showFormValidation(false)}

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WithLayout(Story);
