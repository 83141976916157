import React, { useState, useEffect } from 'react';
// import { useHistory } from 'react-router-dom';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

import { listLibraryItems, deleteLibraryItem } from "../../Services/CommonServices";
import WithLayout from '../../Shared/Components/HeaderFooterHOC';
import loader from "../../Assets/images/loader-1.png";
import DialogBox from '../../Shared/Components/DialogBox';

const LibraryContainer = (props) => {
    // const history = useHistory();
    const [libraryList, setLibraryList] = useState([]);
    const [rowClickData, setRowClickData] = useState();
    const [showLoader, setLoader] = useState(false);

    const [displayDialog, setDisplayDialog] = useState({
        delete: false,
    });

    useEffect(() => {
        getLibrary();
        return () => {
            // componentWillUnmount events
        }
    }, [])

     // get all library data
     const getLibrary = () => {
        setLoader(true);
        listLibraryItems()
            .then((res) => {
                // const arr = res.data;
                // arr.map(item => {
                //     item.launch_date = new Date(item.launch_date).toString();
                // });
                setLibraryList(res.data);
                setLoader(false);
            })
            .catch(err => {
                setLoader(false);
                props.showToast("error", "Error", "Error fetching library list");
            })
    }

    // enable or disable dialog box
    const showDialog = (type, show) => {
        if (type === "delete") {
            setDisplayDialog((preval) => ({ ...preval, delete: show }));
            //   setLoader(false);
        }
    };

    // Redirect to add to library
    const addToLibrary = () => {
        props.history.push('/addLibrary');
    }

    // Redirect to edit
    const editLibraryItem = (rowData) => {
        props.history.push(`/editLibrary/${rowData.id}`);
    }

    // when delete button clicked
    const confirmdeleteLibraryItem = (rowData) => {
        setRowClickData({ ...rowData })
        showDialog("delete", true);
    }

    // to hide delete modal
    const hidedeleteLibraryItemDialog = () => {
        showDialog("delete", false);
    };

    // delete selected library item
    const deleteLibrary = async () => {
        showDialog("delete", false);
        setLoader(true)
        deleteLibraryItem(rowClickData.id)
            .then((res) => {
                if (res) {
                    getLibrary();
                    props.showToast("success", "Success", res.data.message);
                    setLoader(false)
                }
            })
            .catch(err => {
                props.showToast("error", "Error", "Error deleting libray item");
                setLoader(false)
            });
    };

    // table action list area (edit, delete icons)
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    icon="pi pi-pencil" title="Edit"
                    className="p-button-rounded p-button-primary p-button-outlined p-mr-2"
                    onClick={() => editLibraryItem(rowData)}
                />
                <Button
                    icon="pi pi-trash" title="Delete"
                    className="p-button-rounded p-button-danger  p-button-outlined"
                    onClick={() => confirmdeleteLibraryItem(rowData)}
                />
            </React.Fragment>
        );
    };

    // table ui
    return (
        <div>
            <div className="loader-overlay" style={{ display: showLoader ? "flex" : "none" }} >
                <div className="p-d-flex p-flex-column p-ai-center">
                    <div className="loader">
                        <img src={loader} width="100" height="100" />
                    </div>
                    <span className="loading-text p-mt-2">
                        Loading... Please Wait...
                    </span>
                </div>
            </div>


            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5 className="mb-0">
                    Library
                </h5>
                <Button label="Add to Library" className="p-button p-component p-button-rounded p-px-4" icon="pi pi-plus" onClick={addToLibrary} />
            </div>

            <div>
                {displayDialog.delete && (
                    <div>
                        <DialogBox area="topic" type="delete" showDialog={displayDialog.delete} 
                            hideDialog={hidedeleteLibraryItemDialog} product={rowClickData}
                            confirmClick={()=>deleteLibrary()} />
                    </div>
                )}
            </div>
            <div className="card">
                <DataTable value={libraryList} removableSort paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={5} rowsPerPageOptions={[5, 10, 20, 50]} >
                    <Column field="type" header="Type" sortable></Column>
                    <Column field="description" header="Name / Description" sortable></Column>
                    {/* <Column field="created_on" header="Created on" sortable></Column> */}
                    <Column header="Action" body={actionBodyTemplate} style={{ width: "210px" }} ></Column>
                </DataTable>
            </div>
        </div>
    )
}


export default WithLayout(LibraryContainer)