import React, { useState, useEffect } from 'react';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { listAnalytics } from "../../Services/CommonServices";
import WithLayout from '../../Shared/Components/HeaderFooterHOC';
import loader from "../../Assets/images/loader-1.png";
import moment from 'moment';

const AnalyticsContainer = (props) => {

    const [analytics, setAnalytics] = useState([]);
    const [showLoader, setLoader] = useState(false);

    useEffect(() => {
        getAnalytics();
        return () => {
            // componentWillUnmount events
        }
    }, [])

    // get analytics data
    const getAnalytics = () => {
        setLoader(true);
        listAnalytics()
            .then((res) => {
                const arr = res.data;
                arr.map(item => {
                    item.last_seen = moment(item.last_seen).format('DD MMMM YYYY, h:mm:ss a');
                });
                setAnalytics(res.data);
                setLoader(false);
            })
            .catch(err => {
                setLoader(false);
                props.showToast("error", "Error", "Error fetching analytics");
            })
    }

    return ( 
        <div>
            <div className="loader-overlay" style={{ display: showLoader ? "flex" : "none" }} >
                <div className="p-d-flex p-flex-column p-ai-center">
                    <div className="loader">
                        <img src={loader} width="100" height="100" />
                    </div>
                    <span className="loading-text p-mt-2">
                        Loading... Please Wait...
                    </span>
                </div>
            </div>

            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5 className="mb-0">
                    Analytics
                </h5>
            </div>

            <div className="card mt-3">
                <DataTable value={analytics} removableSort paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]} >
                    {/* <Column field="id" header="User Id" sortable></Column> */}
                    <Column field="user_name" header="User" sortable></Column>
                    <Column field="level" header="Level" sortable></Column>
                    <Column field="last_seen" header="Last Seen" sortable></Column>
                    <Column field="tenant_name" header="Tenant" sortable></Column>
                    <Column field="domain_name" header="Domain" sortable></Column>
                </DataTable>
            </div>
        </div>
     );
}
 
export default WithLayout(AnalyticsContainer);