import React, { useEffect, useState } from 'react';

import { getTopic, getCoaches, createTopic, updateTopic, fileUpload } from "../../Services/CommonServices";
import { showFormValidation } from "../../Utilities/Utils";
import loader from "../../Assets/images/loader-1.png";
import WithLayout from "../../Shared/Components/HeaderFooterHOC";
import AudioIcon from '../../Shared/Components/AudioIcon';
import { uuidv4 } from '../../Services/CommonServices'

const AddTopic = (props) => {

    let topicForm = {
        topic_name: "",
        id: uuidv4(),
        task_name: "conversation",
        topic_points: "",
        unlock_level: "",
        description: "",
        default_coach: "",
        coaches: []
    }

    // coaches = [
    //     {
    //         audio_url: "",
    //         coach_id: ""
    //     },
    //     {
    //         audio_url: "",
    //         coach_id: ""
    //     }
    // ]



    const [formObj, setFormObj] = useState(topicForm);
    const [submitted, setSubmitted] = React.useState(false);
    const [editMode, setEditMode] = useState(false);
    const [coach, setCoach] = useState([]);
    const [showLoader, setShowLoader] = useState(false);



    const fileUploadMain = (value) => {
        let data = prevData => ({
            ...prevData,
            coaches: [...prevData.coaches, value]
        })
        setTimeout(() => {
            setFormObj(data)
        }, 200);
    }

    const taskList = [
        { label: 'Conversation', value: 'conversation' },
        { label: 'Experience', value: 'experience' }
    ];

    const getCoach = () => {
        getCoaches()
            .then(res => {
                setCoach(res.data.coaches_list)
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {

        setShowLoader(true);

        const id = props.match.params.id;
        id ? setEditMode(true) : setEditMode(false);


        if (id) {
            getTopic(id)
                .then(res => {
                    patchData(res.data.topic)
                    getCoach()
                })
                .catch(err => {
                    console.log(err);
                })
        } else {
            getCoach()
            setTimeout(() => {
                setShowLoader(false);
            }, 500);
        }

        return () => {
            // componentWillUnmount events
        }

    }, []);



    const patchData = (data) => {

        setFormObj({
            topic_name: data.topic_name,
            task_name: data.task_name,
            topic_points: data.topic_points,
            unlock_level: data.unlock_level,
            description: data.description,
            id: data.id,
            default_coach: data.default_coach,
            coaches: data.coaches
        })

        setTimeout(() => {
            setShowLoader(false);
        }, 500);
    }

    const setInput = (event) => {
        event.preventDefault()
        const { name, value } = event.target;
        setFormObj({ ...formObj, [name]: value });

    };


    const onAddTopicSubmit = (e, type) => {
        e.preventDefault();
        setSubmitted(true);


        if (formObj.topic_name && formObj.task_name && formObj.topic_points && formObj.unlock_level && formObj.description) {
            createTopic(formObj)
                .then(res => {
                    setShowLoader(false);
                    setSubmitted(false);
                    props.showToast("success", "Success", "Topic Created");
                    if (type === 'save') {
                        props.history.push('/topics');
                    } else {
                        props.history.push(`/topic/${res.data.topic.id}`);
                    }

                })
                .catch(err => {
                    console.log(err);
                    setShowLoader(false);
                    props.showToast("error", "Error", "Something went wrong");
                })
        }
        console.log(formObj)
    }

    const onEditTopicSubmit = (e, type) => {
        e.preventDefault();
        setSubmitted(true);

        const id = props.match.params.id;


        if (formObj.topic_name && formObj.task_name && formObj.topic_points && formObj.unlock_level && formObj.description) {
            updateTopic(id, formObj)
                .then(res => {
                    setShowLoader(false);
                    setSubmitted(false);
                    props.showToast("success", "Success", "Topic updated");
                    if (type === 'update') {
                        props.history.push('/topics');
                    } else {
                        props.history.push(`/topic/${res.data.topic.id}`);
                    }

                })
                .catch(err => {
                    console.log(err);
                    setShowLoader(false);
                    props.showToast("error", "Error", "Something went wrong");
                })
        }




    }
    const getAudioUrl = (mapedCoachId, obj) => {


        let coach_list = obj.coaches
        let audioUrl
        coach_list.forEach(eachCoachObj => {
                console.log(eachCoachObj)
            if (eachCoachObj.coach_id == mapedCoachId) {

                return audioUrl = eachCoachObj.audio_url
            }
        }

        )
        return audioUrl
    }

    const removeUrl = (coach_id_) => {
        let newCoaches = formObj.coaches.filter(eachObj => (
            eachObj.coach_id !== coach_id_
        )
        )
        setFormObj(prevData => (
            {
                ...prevData,
                coaches: newCoaches
            }
        ))
    }

    const selectDefaultCoach = (e, coachId) => {
        formObj.default_coach === coachId ? setFormObj(prevData => (
            {
                ...prevData,
                default_coach: ""
            }
        )) :
            setFormObj(prevData => (
                {
                    ...prevData,
                    default_coach: e.target.value
                }
            ))
    }

    return (
        <>
            <div className="loader-overlay" style={{ display: showLoader ? "flex" : "none" }} >
                <div className="p-d-flex p-flex-column p-ai-center">
                    <div className="loader">
                        <img src={loader} width="100" height="100" />
                    </div>
                    <span className="loading-text p-mt-2">Loading... Please Wait...</span>
                </div>
            </div>
            <div className="row">
                <div className="col-md-8 col-xl-6 col-xxl-6 mx-auto">
                    <div className="main-heading-wrapper d-flex align-items-center justify-content-between mb-2">

                        <div className="d-flex align-items-center">
                            <button className="btn btn-clear me-2" onClick={() => props.history.push('/topics')}>
                                <span className="icon icon-arrow_back"></span>
                            </button>
                            {
                                editMode ? <h4 className="main-heading mb-0">Edit Topic</h4>
                                    : <h4 className="main-heading mb-0">Create Topic</h4>
                            }
                        </div>
                    </div>
                </div>
                {/* <button className="p-button p-component p-button-rounded p-px-3">
                    <span className="icon icon-add me-2"></span>
                    Add New Story
                </button> */}
            </div>
            <div className="main-page-content">
                <div className="row">
                    <div className="col-md-8 col-xl-6 col-xxl-6 mx-auto">
                        <div className="card p-5">
                            <div className="row mb-3">
                                <div className="col-md-12">
                                    {/* <h6 className="form-sub-heading">Topic Details</h6> */}
                                    <div className="row">

                                        <div className="col-md-12">
                                            <div className="form-floating mb-3">

                                                <input type="text" name="topic_name" className="form-control" id="topicName"
                                                    value={formObj.topic_name} onChange={(e) => setInput(e)} placeholder="Topic Name" />
                                                {submitted && !formObj.topic_name
                                                    ? showFormValidation(true, true, 'Topic Name required')
                                                    : showFormValidation(false)
                                                }
                                                <label htmlFor="topicName">Topic Name <span className="red">*</span></label>

                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="form-floating mb-3">

                                                <input type="number" name="topic_points" className="form-control" id="topicPoints"
                                                    value={formObj.topic_points} onChange={(e) => setInput(e)} placeholder="Topic Points" />
                                                {submitted && !formObj.topic_points
                                                    ? showFormValidation(true, true, 'Topic points required')
                                                    : showFormValidation(false)
                                                }
                                                <label htmlFor="topicPoints">Topic Points <span className="red">*</span></label>

                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating mb-3">

                                                <input type="number" name="unlock_level" className="form-control" id="unloackLevel"
                                                    value={formObj.unlock_level} onChange={(e) => setInput(e)} placeholder="Unlock Level" />
                                                {submitted && !formObj.unlock_level
                                                    ? showFormValidation(true, true, 'Unlock level required')
                                                    : showFormValidation(false)
                                                }
                                                <label htmlFor="unloackLevel">Unlock Level <span className="red">*</span></label>

                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-floating mb-3">

                                                <input type="text" name="task_name" className="form-control" id="topicType"
                                                    value={formObj.task_name} onChange={(e) => setInput(e)} placeholder="Topic Type" disabled />
                                                {submitted && !formObj.task_name
                                                    ? showFormValidation(true, true, 'Select a Task type')
                                                    : showFormValidation(false)
                                                }
                                                <label htmlFor="topicType">Task Type</label>

                                                {/* <select value={formObj.task_name} onChange={(e) => setInput(e)} className="form-select"
                                                    id="topicType" aria-label="Topic Type" name="task_name">
                                                    <option value="" disabled >Select</option>
                                                    {
                                                        taskList.map((item, index) => (
                                                            <option key={index} value={item.value}>
                                                                {item.label}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                                {submitted && !formObj.task_name
                                                    ? showFormValidation(true, true, 'Select a Task type')
                                                    : showFormValidation(false)
                                                }
                                                <label htmlFor="topicType">Task Type  <span className="red">*</span></label> */}
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="agents-list mt-2">

                                                <h6 className="form-sub-heading mb-3">Coaches</h6>
                                                <div className="conersation-agents-wrap row">

                                                    {
                                                        coach.map(coach => (
                                                            <div className="col-4 p-text-center">
                                                                <div key={coach.id} className="conersation-agent p-mr-0 p-mb-4">
                                                            
                                                                            <div className="conersation-agent-img p-mx-auto">
                                                                                <img src={coach.img_url} />
                                                                            </div>

                                                                            <div className="agent-name d-flex p-ai-center p-jc-center p-mt-1">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        value={coach.id}
                                                                                        name='defaultCoach'
                                                                                        onChange={(e) => selectDefaultCoach(e, coach.id)}
                                                                                        checked={formObj.default_coach == coach.id}
                                                                                    />
                                                                                    <label htmlFor="defaultCoach" className='p-ml-2'>{coach.coach_name}</label>
                                                                            </div>
                                                                            <div className="audio-upload p-d-flex p-jc-center">
                                                                                <AudioIcon coach_id={coach.id} audioURL={editMode && getAudioUrl(coach.id, formObj)} fileUploadMain={fileUploadMain} topic_id={formObj.id} editMode={editMode} removeAudioFile={removeUrl} />
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className='col-6'>
                                                                                    <AudioIcon coach_id={coach.id} audioURL={editMode && getAudioUrl(coach.id, formObj)} fileUploadMain={fileUploadMain} topic_id={formObj.id} editMode={editMode} removeAudioFile={removeUrl} />
                                                                                </div> */}
                                                                    </div>


                                                        ))
                                                    }
                                                </div>
                                                {/* {submitted && !formObj.default_coach
                                                            ? showFormValidation(true, true, 'Select a Default Coach')
                                                            : showFormValidation(false)
                                                        } */}
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-floating mt-3">
                                                <textarea className="form-control" name="description" placeholder="Description"
                                                    value={formObj.description} onChange={(e) => setInput(e)} id="desc" rows="4" />
                                                {submitted && !formObj.description
                                                    ? showFormValidation(true, true, 'Description required')
                                                    : showFormValidation(false)
                                                }
                                                <label htmlFor="desc">Description</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-2 text-center form-save-btns">
                                <div className="col-md-12">
                                    <button onClick={() => props.history.push('/topics')} title="Cancel" className="p-button p-component p-button-rounded p-button-secondary p-mr-2 p-button-icon-only btn-lg" >
                                        <i className='pi pi-times'></i>
                                    </button>
                                    {
                                        editMode
                                            ?
                                            <>
                                                <button onClick={(e) => onEditTopicSubmit(e, 'update')} title="Update"
                                                    className="p-button p-component p-button-rounded p-button-success p-button-icon-only btn-lg" >
                                                    <i className='pi pi-save'></i>
                                                </button>
                                                {/* <button className="p-button p-component p-button-rounded p-px-4 p-py-3 p-ml-2"
                                                onClick={(e) => onEditTopicSubmit(e,'update_continue')}>Update and Create Story
                                            </button>  */}
                                            </>
                                            :
                                            <>
                                                {/* <button  title="Save" onClick={(e) => onAddTopicSubmit(e,'save')}
                                                className="p-button p-component p-button-rounded p-button-success p-button-icon-only btn-lg" >
                                                <i className='pi pi-save'></i>
                                            </button> */}
                                                <button className="p-button p-component p-button-rounded p-px-4 p-py-3 p-ml-2"
                                                    onClick={(e) => onAddTopicSubmit(e, 'save_continue')}>Save and Create Story
                                                </button>
                                            </>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WithLayout(AddTopic)
