import React, { useEffect, useState } from 'react'
import { fileUpload } from '../../Services/CommonServices'

import voiceIcon from "../../Assets/images/voice.svg"
import loader from "../../Assets/images/loader-1.png";

const AudioIcon = ({ coach_id, audioURL, fileUploadMain, topic_id, editMode, removeAudioFile }) => {

    const [file, setFile] = useState("")
    const [fileValue, setFileValue] = useState("")
    const [coachesValue, setCoachesValue] = useState({})
    const [showLoader, setShowLoader] = useState(false);

    const uploadAudioFile = async (e) => {

        e.preventDefault()
        if (e.target.files.length === 0) {
            return;
        }

        setShowLoader(true);
        const file_name = e.target.files[0].name
        const coach_unique_id = e.target.id

        const encodedFileName = await getBase64(e.target.files[0])

        const body = {
            "file_name": file_name,
            "type": "topic_file",
            "topic_id": topic_id,
            "coach_id": coach_unique_id,
            "base64_string": encodedFileName
        }

        fileUpload(body)
            .then(ress => {
                if (ress.data.url) {
                    console.log(ress)
                    setTimeout(() => {
                        setFile(ress.data.url.replace('ap-south-1','us-east-1'))
                    }, 100)
                    setShowLoader(false);
                    setCoachesValue({
                        "audio_url": ress.data.url.replace('ap-south-1','us-east-1'),
                        "coach_id": body.coach_id
                    })
                }
            }).catch(err => {
                setShowLoader(false);
                console.log(err);
            })
    }
    useEffect(() => {
        coachesValue.audio_url && coachesValue.coach_id && fileUploadMain(coachesValue)
        editMode && audioURL !== undefined && setFile(audioURL)
    }, [coachesValue])



    const removeAudio = () => {
        removeAudioFile(coach_id)
        setFile("")
    }

    const getBase64 = (file) => {

        return new Promise((resolve, reject) => {

            let baseURL = "";

            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                baseURL = reader.result.replace("data:", "").replace(/^.+,/, "");

                if (baseURL) {
                    resolve(baseURL);
                } else {
                    reject("error")
                }

            };
        });
    };

    return (
        <>
            <div className="loader-overlay" style={{ display: showLoader ? "flex" : "none" }} >
                <div className="p-d-flex p-flex-column p-ai-center">
                    <div className="loader">
                        <img src={loader} width="100" height="100" />
                    </div>
                    <span className="loading-text p-mt-2">Loading... Please Wait...</span>
                </div>
            </div>
            <div className="d-flex align-items-center qn-with-audio p-mt-1">
                <div  style={{ opacity: file ? ".3" : "1" }}>
                    <label className="media" htmlFor={coach_id} >
                        <span className="voice-icon">
                            <img src={voiceIcon} />
                        </span>
                        <span className="ms-2">
                            Upload Audio
                        </span>
                    </label>
                    <input
                        disabled={file ? true : false}
                        id={coach_id}
                        type="file"
                        name="file_upload"
                        accept='audio/*'
                        value={fileValue}
                        onChange={(e) => uploadAudioFile(e)}
                    />
                </div>
                {file &&
                    <div className="ms-1">
                        <span className="icon icon-close audio-icon-close" onClick={removeAudio}></span>
                    </div>
                }
            </div>
        </>
    )
}

export default AudioIcon