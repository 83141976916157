import React, { useState, useEffect } from 'react';

import { MultiSelect } from "primereact/multiselect";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { listTopics, addTenant, getTenant, updateTenant } from "../../Services/CommonServices";

import WithLayout from "../../Shared/Components/HeaderFooterHOC";
import { TEXT_LABELS } from "../../Utilities/TextLabels";
import { showFormValidation, validateEmail } from "../../Utilities/Utils";
import loader from "../../Assets/images/loader-1.png";

const AddTenant = (props) => {

    const tenantForm = {
        tenant_name: '',
        company_website: '',
        domain_name: '',
        contact_person_full_name: '',
        email: '',
        contact_person_no: '',
        topics_selected: [],
        address_line1: '',
        address_line2: '',
        city_name: '',
        state_name: '',
        country_name: '',
        zip_code: '',
        no_of_employees: '',
        expected_nos: '',
        description: '',
        active: true
    }

    const [formObj, setFormObj] = useState(tenantForm);
    const [submitted, setSubmitted] = React.useState(false);
    const [topics, SetTopics] = React.useState([]);
    const [editMode, setEditMode] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {

        setShowLoader(true);
        let topicsAr = [];

        // get all topics list
        await listTopics().then((list) => {
            list.data.topic_list.map((topic) => {
                topicsAr.push({ code: topic.id, name: topic.topic_name });
            });
        });
        SetTopics(topicsAr);

        // check add tenant or edit tenant
        const id = props.match.params.id;
        id ? setEditMode(true) : setEditMode(false);

        if (id) {

            getTenant(id)
                .then(res => {
                    patchData(res.data, topicsAr)
                })
                .catch(err => {
                    console.log(err);
                })
        } else {
            setTimeout(() => {
                setShowLoader(false);
            }, 500);
        }

        return () => {
            // componentWillUnmount events
          }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // patch tenant data values
    const patchData = (data, topicList) => {

        const arr = data.topic_list;

        var filteredKeywords = topicList.filter((word) => {
            return arr.includes(word.code)
        });

        setFormObj({
            tenant_name: data.tenant_name,
            company_website: data.company_website,
            domain_name: data.domain_name,
            contact_person_full_name: data.contact_person_name,
            email: data.email,
            topics_selected: filteredKeywords,
            contact_person_no: data.phone_number,
            address_line1: data.address_line1,
            address_line2: data.address_line2,
            city_name: data.city_name,
            state_name: data.state_name,
            country_name: data.country_name,
            zip_code: data.zip_code,
            no_of_employees: data.no_of_employees,
            expected_nos: data.expected_nos,
            description: data.description,
            active : data.active
        })
        setTimeout(() => {
            setShowLoader(false);
        }, 500);
    }

    // setting input values
    const setInput = (event) => {
        event.preventDefault()
        const { name, value } = event.target;
        setFormObj({ ...formObj, [name]: value });
    };

    // convert letters to lowercase (for domain name)
    const toInputLowercase = e => {
        e.target.value = ("" + e.target.value).toLowerCase();
    };

    const checkDomain  = (email) => {
        if(email) {
        const domain_name = email.split("@");
        return formObj.domain_name === domain_name[1];
        }
    }

    // add tenant
    const onAddTenantSubmit = async (e) => {

        e.preventDefault();
        setSubmitted(true);

        const domain = formObj.email.split("@");
        const domainCheck = (domain[1] === formObj.domain_name);

        if (formObj.tenant_name && formObj.company_website && formObj.domain_name && formObj.contact_person_full_name
            && formObj.topics_selected.length > 0 && formObj.email && formObj.contact_person_no && validateEmail(formObj.email)
        && domainCheck) {

            setSubmitted(false);
            setShowLoader(true);

            addTenant(formObj)
                .then(res => {
                    console.log(res);
                    setShowLoader(false);
                    props.showToast("success", "Success", "Tenant Created");
                    props.history.push('/tenant')

                })
                .catch(error => {
                    setShowLoader(false);
                    if (error.response.status === 409 && error.response.data.class === "UsernameExistsException") {
                        props.showToast("error", "error", "An account with the given email already exists.");
                    } else if (error.response.status === 400) {
                        props.showToast("error", "error", error.response.data.message);
                    } else {
                        props.showToast("error", "error", error.response.data.message);
                    }
                })

        }
    }

    // edit tenant
    const onEditTenantSubmit = (e) => {

        e.preventDefault();
        setSubmitted(true);

        const id = props.match.params.id;

        if (formObj.tenant_name && formObj.company_website && formObj.domain_name && formObj.contact_person_full_name
            && formObj.topics_selected.length > 0 && formObj.email && formObj.contact_person_no && validateEmail(formObj.email)
            && formObj.contact_person_no.length > 8) {

            updateTenant(id, formObj)
                .then(res => {
                    setShowLoader(false);
                    props.showToast("success", "Success", "Tenant Updated");
                    props.history.push('/tenant')
                })
                .catch(error => {
                    setShowLoader(false);
                    props.showToast("error", "error", error.response.data.message);
                })
        }

    }

    return (
        <div>
            <div className="row">
                <div className="col-md-8 col-xl-8 col-xxl-8 mx-auto">
                    <div className="loader-overlay" style={{ display: showLoader ? "flex" : "none" }} >
                        <div className="p-d-flex p-flex-column p-ai-center">
                            <div className="loader">
                                <img src={loader} width="100" height="100" />
                            </div>
                            <span className="loading-text p-mt-2">Loading... Please Wait...</span>
                        </div>
                    </div>

                    <div className="main-heading-wrapper d-flex align-items-center justify-content-between mb-2">
                        <div className="d-flex align-items-center">
                            <button className="btn btn-clear me-2" onClick={() => props.history.push('/tenant')}>
                                <span className="icon icon-arrow_back"></span>
                            </button>
                            {
                                editMode ? <h4 className="main-heading mb-0">Edit Tenant</h4>
                                    : <h4 className="main-heading mb-0">Create Tenant</h4>
                            }
                        </div>
                        {/* <button className="p-button p-component p-button-rounded p-px-3">
                    <span className="icon icon-add me-2"></span>
                    Add New Story
                </button> */}
                    </div>
                </div>
            </div>
            <div className="main-page-content">
                <div className="row">
                    <div className="col-md-8 col-xl-8 col-xxl-8 mx-auto">
                        <div className="card p-5 pb-4">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="form-sub-heading">Company Details</h6>
                                    <div className="row">

                                        <div className="col-md-12">
                                            <div className="form-floating mb-3">

                                                <input type="text" name="tenant_name" className="form-control" value={formObj.tenant_name}
                                                    id="tenantName" placeholder="Tenant Name" onChange={(e) => setInput(e)} />

                                                {submitted && !formObj.tenant_name
                                                    ? showFormValidation(true, true, 'Tenant name required')
                                                    : showFormValidation(false)}

                                                <label htmlFor="tenantName">Tenant Name <span className="red">*</span></label>

                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-floating mb-3">
                                                <input type="text" name="company_website" className="form-control" id="comapnyWebsite"
                                                    placeholder="Tenant Name" onChange={(e) => setInput(e)} value={formObj.company_website} />

                                                {submitted && !formObj.company_website
                                                    ? showFormValidation(true, true, 'Company website required')
                                                    : showFormValidation(false)
                                                }

                                                <label htmlFor="comapnyWebsite">Company Website <span className="red">*</span></label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-floating mb-3">
                                                <input type="text" name="domain_name" disabled={editMode ? true : false} className="form-control" id="domainName"
                                                    onInput={toInputLowercase} placeholder="Tenant Name" onChange={(e) => setInput(e)} value={formObj.domain_name} />

                                                {submitted && !formObj.domain_name
                                                    ? showFormValidation(true, true, 'Domain name required')
                                                    : showFormValidation(false)
                                                }
                                                <label htmlFor="domainName">Domain name <span className="red">*</span></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <h6 className="form-sub-heading">Contact Person Details</h6>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-floating mb-3">
                                                <input type="text" name="contact_person_full_name" className="form-control" id="fullName"
                                                    placeholder="Tenant Name" onChange={(e) => setInput(e)} value={formObj.contact_person_full_name} />

                                                {submitted && !formObj.contact_person_full_name
                                                    ? showFormValidation(true, true, 'Conatct Name required')
                                                    : showFormValidation(false)
                                                }

                                                <label htmlFor="fullName">Full Name <span className="red">*</span></label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-floating mb-3">
                                                <input type="text" name="email" disabled={editMode ? true : false} className="form-control" id="email"
                                                    placeholder="Tenant Name" onChange={(e) => setInput(e)} value={formObj.email} />

                                                {   submitted && !formObj.email
                                                    ? showFormValidation(true, true, TEXT_LABELS.tenantModule.requiredAddFrom.email)
                                                    : !validateEmail(formObj.email) && submitted
                                                    ? showFormValidation(true, true, TEXT_LABELS.tenantModule.requiredAddFrom.emailInvalid)
                                                    : !checkDomain(formObj.email)&& submitted 
                                                    ? showFormValidation(true, true, "Domain name and domain name of email-id should be same")
                                                    :showFormValidation(false)
                                                }
                                                <label htmlFor="email">Email (Used for tenant Registration) <span className="red">*</span></label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-floating custom-phone mb-3">

                                                {/* <input type="number" name="contact_person_no" className="form-control" id="phoneNumber"
                                                    placeholder="Tenant Name" onChange={(e) => setInput(e)} value={formObj.contact_person_no} /> */}

                                                < PhoneInput country="au" name="contact_person_no" value={formObj.contact_person_no}
                                                    onChange={phone => setFormObj({ ...formObj, contact_person_no: phone })} />
                                                {
                                                    submitted && !formObj.contact_person_no
                                                        ? showFormValidation(true, true, 'Contact number required')
                                                        : formObj.contact_person_no.length <= 8 && submitted
                                                            ? showFormValidation(true, true, 'Minimum 9 digits required')
                                                            : showFormValidation(false)
                                                }
                                                {/* <label htmlFor="phoneNumber">Contact No.</label> */}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>


                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <h6 className="form-sub-heading">Choose Topics  <span className="red">*</span></h6>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <MultiSelect value={formObj.topics_selected} options={topics} optionLabel="name"
                                                name="topics_selected" placeholder="Select a Topic" className="w-100" display="chip"
                                                onChange={(e) => setInput(e)} />
                                            {submitted && !formObj.topics_selected.length
                                                ? showFormValidation(true, true, "Select a topic")
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <h6 className="form-sub-heading mt-2">Company Address</h6>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-floating mb-3">
                                                <input type="text" name="address_line1" className="form-control" id="address1"
                                                    placeholder="Tenant Name" onChange={(e) => setInput(e)} value={formObj.address_line1} />
                                                {/* { submitted && !formObj.address_line1
                                                        ? showFormValidation( true, true, 'Address required')
                                                        : showFormValidation(false)
                                                    } */}
                                                <label htmlFor="address1">Address Line 1</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating mb-3">
                                                <input type="text" name="address_line2" className="form-control" id="address2"
                                                    placeholder="Tenant Name" onChange={(e) => setInput(e)} value={formObj.address_line2} />
                                                <label htmlFor="address2">Address Line 2</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating mb-3">
                                                <input type="text" name="city_name" className="form-control" id="city"
                                                    placeholder="Tenant Name" onChange={(e) => setInput(e)} value={formObj.city_name} />
                                                {/* { submitted && !formObj.city_name
                                                        ? showFormValidation( true, true, 'City name required')
                                                        : showFormValidation(false)
                                                    } */}
                                                <label htmlFor="city">City</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating mb-3">
                                                <input type="text" name="state_name" className="form-control" id="state"
                                                    placeholder="Tenant Name" onChange={(e) => setInput(e)} value={formObj.state_name} />
                                                {/* { submitted && !formObj.state_name
                                                        ? showFormValidation( true, true, 'State name required')
                                                        : showFormValidation(false)
                                                    } */}
                                                <label htmlFor="state">State</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating mb-3">
                                                <input type="text" name="country_name" className="form-control" id="country"
                                                    placeholder="Tenant Name" onChange={(e) => setInput(e)} value={formObj.country_name} />
                                                {/* { submitted && !formObj.country_name
                                                        ? showFormValidation( true, true, 'Country name required')
                                                        : showFormValidation(false)
                                                    } */}
                                                <label htmlFor="country">Country</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating mb-3">
                                                <input type="number" name="zip_code" className="form-control" id="zip"
                                                    placeholder="Tenant Name" onChange={(e) => setInput(e)} value={formObj.zip_code} />
                                                {/* { submitted && !formObj.zip_code
                                                        ? showFormValidation( true, true, 'Zip code required')
                                                        : showFormValidation(false)
                                                    } */}
                                                <label htmlFor="zip">Zip</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <h6 className="form-sub-heading mt-2">Number of employees</h6>
                                    <div className="row mb-3">
                                        <div className="col-md-12">
                                            <div className="form-floating mb-3">
                                                <input type="text" name="no_of_employees" className="form-control" id="noOfEmployees"
                                                    placeholder="Tenant Name" onChange={(e) => setInput(e)} value={formObj.no_of_employees} />
                                                <label htmlFor="noOfEmployees">Count</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <h6 className="form-sub-heading mt-2">Expected no. of participats</h6>
                                    <div className="row mb-3">
                                        <div className="col-md-12">
                                            <div className="form-floating mb-3">
                                                <input type="text" name="expected_nos" className="form-control" id="count"
                                                    placeholder="Tenant Name" onChange={(e) => setInput(e)} value={formObj.expected_nos} />
                                                <label htmlFor="count">Count</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h6 className="form-sub-heading mt-2">Why did you register for this program?</h6>
                            <div className="row mb-3">
                                <div className="col-md-12">

                                    <div className="form-floating">
                                        <textarea className="form-control" name="description" placeholder="Description"
                                            id="desc" rows="4" onChange={(e) => setInput(e)} value={formObj.description} />
                                        <label htmlFor="desc">Description</label>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-2 text-center form-save-btns">
                                <div className="col-md-12">
                                    <button onClick={() => props.history.push('/tenant')} title="Cancel" className="p-button p-component p-button-rounded p-button-secondary p-mr-2 p-button-icon-only btn-lg" >
                                        <i className='pi pi-times'></i>
                                    </button>
                                    {
                                        editMode
                                            ? <button onClick={(e) => { onEditTenantSubmit(e) }} title="Update"
                                                className="p-button p-component p-button-rounded p-button-success p-button-icon-only btn-lg" >
                                                <i className='pi pi-save'></i>
                                            </button>
                                            : <button onClick={(e) => { onAddTenantSubmit(e) }} title="Save"
                                                className="p-button p-component p-button-rounded p-button-success p-button-icon-only btn-lg" >
                                                <i className='pi pi-save'></i>
                                            </button>
                                    }
                                </div>
                            </div>


                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default WithLayout(AddTenant)
