import React, { useEffect, useState } from "react";
import styles from "../../Assets/css/dashboard.module.scss";
import ButtonWrapper from "../../Shared/Components/Button";
import TenantListContainer from "../Tenant/TenantContainer";

import { listTopics, loadTenantList } from '../../Services/CommonServices'

function SuperAdminDashboard(props) {

  const [topics, setTopics] = useState([]);
  const [experiences, setExperience] = useState([])
  const [conversations, setConversation] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [activeTenants, setActiveTenants] = useState('');
  const [inActiveTenants, setInActiveTenants] = useState('');

  useEffect(() => {
    listTopics()
    .then(res=>{
      // console.log(res)
      setTopics(res.data.topic_list);

      var conv_item = res.data.topic_list.filter(item => item.task_name === 'conversation');
      setConversation(conv_item);

      let exp_item = res.data.topic_list.filter(item => item.task_name === 'experience');
      setExperience(exp_item);

    })
    .catch(err => {
      console.log(err)
    })

    loadTenantList()
    .then(res => {

      setTenants(res.data.tenant_list.length);
      
      const active = res.data.tenant_list.filter(tenant => tenant.active === true);
      setActiveTenants(active.length);

      const inactive = res.data.tenant_list.filter(tenant => tenant.active === false);
      setInActiveTenants(inactive.length);
      
    })
    .catch(err => {
      console.log(err);
    })
  },[])

  return (
    <div className="dashboard-wrap">
      {/*<div className={styles.dashboardPage}>
        <h2 className="main-heading">
          Super Admin Dashboard
           <ButtonWrapper
            label="Settings"
            icon="pi pi-cog"
            className="p-button p-component p-button-sm settings-button"
            onClick={() => props.history.push("/admin-settings")}
          /> 
        </h2>
      </div>

      {/* <div className="gridArea">
        <h4 className="innerHeader">Tenant Details</h4>
        <div className="gridWrapper">
          <TenantListContainer {...props} />
        </div>
      </div> */}

      <div className="dashboard-info">
        <div className="row dashboard-info-cards-row">
          <div className="col col-md-4">
            <div className="card dashboard-card d-flex flex-row align-items-center p-3">
              <div className="card-icon me-2 justify-content-center">
                <i className="pi pi-users"></i>
              </div>
              <div className="card-data">
                <div className="d-flex">
                  <div className="card-value me-4 active">
                    <span className="card-val">{activeTenants? activeTenants : '0'}</span> <span className="card-val-label">Active</span>
                  </div>
                  <div className="card-value inactive">
                    <span className="card-val">{inActiveTenants ? inActiveTenants : '0'}</span> <span className="card-val-label">Inactive</span>
                  </div>
                </div>
                <div className="card-label">
                  No. of registered <strong>tenants</strong><strong> : {tenants? tenants : '0'}</strong>
                </div>

              </div>

            </div>
          </div>
          <div className="col col-md-4">
            <div className="card dashboard-card d-flex flex-row align-items-center p-3">
              <div className="card-icon me-2 justify-content-center">
                <i className="pi pi-book"></i>
              </div>
              <div className="card-data">
                <div className="d-flex">

                  <div className="card-value">
                    {experiences ? experiences.length : '0'}
                  </div>
                </div>
                <div className="card-label">
                  No of topics available - <strong>Experience</strong>
                </div>

              </div>

            </div>
          </div>
          <div className="col col-md-4">
            <div className="card dashboard-card d-flex flex-row align-items-center p-3">
              <div className="card-icon me-2 justify-content-center">
                <i className="pi pi-book"></i>
              </div>
              <div className="card-data">
                <div className="d-flex">

                  <div className="card-value">
                    {conversations ? conversations.length : '0'}
                  </div>
                </div>
                <div className="card-label">
                  No of topics available - <strong>Conversation</strong>
                </div>

              </div>

            </div>
          </div>


        </div>

        <div className="row mt-3">
          <div className="col-md-8">
            <div className="card">
              <div className="card-header px-3 py-2">

                <strong>Section Heading</strong>
              </div>
              <div className="card-body py-2 px-3">

              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-header px-3 py-2">

                <strong>Common Topics</strong>
              </div>
              <div className="card-body py-2 px-3">
                <div className="topics-list">
                  <ul>

                     {
                       topics.slice(0, 5).map(item => (                         
                        <li key={item.id}><i className="pi pi-book me-2"></i>{item.topic_name}</li>
                       ))
                     }
                    {/* <li><i className="pi pi-book me-2"></i> Racial pay inequality</li>
                    <li><i className="pi pi-book me-2"></i> Reaction to police shooting</li>
                    <li><i className="pi pi-book me-2"></i> Education achievement gap</li>
                    <li><i className="pi pi-book me-2"></i> Professional advancement</li>
                    <li><i className="pi pi-book me-2"></i> Healthcare Inequity</li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}

export default SuperAdminDashboard;
