import React from "react";

import { TEXT_LABELS } from "../../Utilities/TextLabels";
import InputTextWrapper from "../../Shared/Components/InputText";
import ButtonWrapper from "../../Shared/Components/Button";
import { showFormValidation, validateEmail } from "../../Utilities/Utils";
import { actions } from "../../Services/UsermanagementServices";

const ForgotPassword = (props) => {
  const { action, sendCodeOnClick, resetPasswordOnClick } = props;
  const [submitted, setSubmitted] = React.useState(false);
  const [formObj, setFormObj] = React.useState({
    email: "",
    password: "",
    confirmPwd: "",
    code: "",
  });
  const login = "/login";

  const sendCodeSubmit = () => {
    setSubmitted(true);
    if (formObj.email && validateEmail(formObj.email)) {
      sendCodeOnClick(formObj.email);
      setSubmitted(false);
    }
  };
  const sendCodresetPasswordSubmit = () => {
    setSubmitted(true);
    if (
      formObj.email &&
      formObj.code &&
      formObj.password &&
      formObj.confirmPwd &&
      formObj.password === formObj.confirmPwd
    ) {
      resetPasswordOnClick(formObj).then(() => {
        setSubmitted(false);
      });
    }
  };
  const setInput = (event) => {
    setSubmitted(false);
    const { name, value } = event.target;
    setFormObj({ ...formObj, [name]: value });
  };
  const checkPwdSame = () => {
    return formObj.password === formObj.confirmPwd;
  };
  return (
    <>
      {/* <div className="p-container h-100">
                <div className=" p-grid m-0 h-100">
                    <div className="p-col-6 h-100 login-left-image">
                    </div>
                    <div className="p-col-6 h-100 login-form-wrapper">
                        <div className="login-logo">
                        </div>
                        {
                            action === actions.sendCode ? (
                                <div className="form-area">
                                    <div>
                                        <label htmlFor="user_email">{TEXT_LABELS.userManagementModule.email} </label>
                                        <span> *</span>
                                        <InputTextWrapper id="user_email" name='email' type="text" value={formObj.username} required autoFocus onChange={setInput} />
                                        {(submitted && !formObj.email)
                                            ? showFormValidation(true, true, TEXT_LABELS.userManagementModule.required.email)
                                            : (!validateEmail(formObj.email) && submitted ? showFormValidation(true, true, TEXT_LABELS.userManagementModule.required.emailInvalid)
                                                : showFormValidation(false))}
                                    </div>
                                    <div className="p-field">
                                        <ButtonWrapper className="login-btn" label={TEXT_LABELS.userManagementModule.sendCode} icon="pi pi-envelope" iconPos="right" onClick={sendCodeSubmit} />
                                    </div>
                                </div>
                            ) : (
                                <div className="form-area">
                                    <div>
                                        <label htmlFor="user_code">{TEXT_LABELS.userManagementModule.code} </label>
                                        <span> *</span>
                                        <InputTextWrapper id="user_code" name='code' aria-describedby="code-help" type="text" value={formObj.code} required autoFocus onChange={setInput} />
                                        <small id="code-help" className="p-text-italic code-help">{TEXT_LABELS.userManagementModule.codehelp}</small>
                                        {(submitted && !formObj.code)
                                            ? showFormValidation(true, true, TEXT_LABELS.userManagementModule.required.code)
                                            : showFormValidation(false)}
                                    </div>
                                    <div>
                                        <label htmlFor="reset_pwd">{TEXT_LABELS.userManagementModule.pwd}</label>
                                        <span> *</span>
                                        <InputTextWrapper id="reset_pwd" type="password" name='password' value={formObj.password} required onChange={setInput} />
                                        {(submitted && !formObj.password)
                                            ? showFormValidation(true, true, TEXT_LABELS.userManagementModule.required.pwd)
                                            : showFormValidation(false)}
                                    </div>
                                    <div>
                                        <label htmlFor="reset_confirm_pwd">{TEXT_LABELS.userManagementModule.confirmPwd}</label>
                                        <span> *</span>
                                        <InputTextWrapper id="reset_confirm_pwd" type="password" name='confirmPwd' value={formObj.confirmPwd} required onChange={setInput} />
                                        {(submitted && !formObj.confirmPwd)
                                            ? showFormValidation(true, true, TEXT_LABELS.userManagementModule.required.confirmPwd)
                                            : (submitted && !checkPwdSame() ? showFormValidation(true, true, TEXT_LABELS.userManagementModule.required.pwdMisMatch)
                                                : showFormValidation(false))}
                                    </div>
                                    <div className="p-field">
                                        <ButtonWrapper className="login-btn" label={TEXT_LABELS.userManagementModule.resetPwd} icon="pi pi-key" iconPos="right" onClick={sendCodresetPasswordSubmit} />
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div> */}

      <div className="auth-wrapper w-100 h-100 d-flex align-items-center flex-column justify-content-center">
        <div className="auth-box text-center px-4 pb-4 pt-4 rounded">
          <h6 className="auth-head mb-3">Forgot Password</h6>
          {action === actions.sendCode ? (
            <div className="form-area">
              <div className="form-floating mb-3">
                <InputTextWrapper
                  id="user_email"
                  name="email"
                  type="text"
                  className="form-control"
                  placeholder={TEXT_LABELS.userManagementModule.email}
                  value={formObj.username}
                  required
                  autoFocus
                  onChange={setInput}
                />
                <label htmlFor="user_email">
                  {TEXT_LABELS.userManagementModule.email}{" "}
                  <span className="red">*</span>
                </label>

                {submitted && !formObj.email
                  ? showFormValidation(
                      true,
                      true,
                      TEXT_LABELS.userManagementModule.required.email
                    )
                  : !validateEmail(formObj.email) && submitted
                  ? showFormValidation(
                      true,
                      true,
                      TEXT_LABELS.userManagementModule.required.emailInvalid
                    )
                  : showFormValidation(false)}
              </div>
              <div className="button-wrapper">
                <button
                  className="btn btn-primary px-4"
                  label={TEXT_LABELS.userManagementModule.sendCode}
                  // icon="pi pi-envelope"
                  // iconPos="left"
                  onClick={sendCodeSubmit}
                >Send</button>
              </div>
            </div>
          ) : (
            <div className="form-area">
              <small id="code-help" className="red fw-bold mb-2 d-block">
                {TEXT_LABELS.userManagementModule.codehelp}{" "}
              </small>
              <div className="form-floating mb-3">
                <InputTextWrapper
                  id="user_code"
                  name="code"
                  className="form-control"
                  placeholder={TEXT_LABELS.userManagementModule.code}
                  aria-describedby="code-help"
                  type="text"
                  value={formObj.code}
                  required
                  autoFocus
                  onChange={setInput}
                />
                <label htmlFor="user_code">
                  {TEXT_LABELS.userManagementModule.code}{" "}
                  <span className="red">*</span>
                </label>
                {submitted && !formObj.code
                  ? showFormValidation(
                      true,
                      true,
                      TEXT_LABELS.userManagementModule.required.code
                    )
                  : showFormValidation(false)}
              </div>
              <div className="form-floating mb-3">
                <InputTextWrapper
                  id="reset_pwd"
                  type="password"
                  className="form-control"
                  name="password"
                  placeholder={TEXT_LABELS.userManagementModule.pwd}
                  value={formObj.password}
                  required
                  onChange={setInput}
                />
                <label htmlFor="reset_pwd">
                  {TEXT_LABELS.userManagementModule.pwd}{" "}
                  <span className="red">*</span>
                </label>
                {submitted && !formObj.password
                  ? showFormValidation(
                      true,
                      true,
                      TEXT_LABELS.userManagementModule.required.pwd
                    )
                  : showFormValidation(false)}
              </div>
              <div className="form-floating mb-3">
                <InputTextWrapper
                  id="reset_confirm_pwd"
                  type="password"
                  className="form-control"
                  name="confirmPwd"
                  value={formObj.confirmPwd}
                  placeholder={TEXT_LABELS.userManagementModule.confirmPwd}
                  required
                  onChange={setInput}
                />
                <label htmlFor="reset_confirm_pwd">
                  {TEXT_LABELS.userManagementModule.confirmPwd}{" "}
                  <span className="red">*</span>
                </label>
                {submitted && !formObj.confirmPwd
                  ? showFormValidation(
                      true,
                      true,
                      TEXT_LABELS.userManagementModule.required.confirmPwd
                    )
                  : submitted && !checkPwdSame()
                  ? showFormValidation(
                      true,
                      true,
                      TEXT_LABELS.userManagementModule.required.pwdMisMatch
                    )
                  : showFormValidation(false)}
              </div>
              <div className="p-field">
                <ButtonWrapper
                  className="p-button p-button-primary py-3 px-5"
                  label={TEXT_LABELS.userManagementModule.resetPwd}
                  onClick={sendCodresetPasswordSubmit}
                />
              </div>
            </div>
          )}

          <div className="text-center mt-3">
            Back to{" "}
            <a href={{ login }} className="anchor-text">
              Sign In
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
