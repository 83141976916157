import React, { useEffect } from "react";
import PropTypes from "prop-types";

import "../../Assets/css/login.module.scss";
import { useGlobalState } from "../../Context";
import { TEXT_LABELS } from "../../Utilities/TextLabels";
import InputTextWrapper from "../../Shared/Components/InputText";
import ButtonWrapper from "../../Shared/Components/Button";
import { showFormValidation } from "../../Utilities/Utils";
import { trackPromise } from "react-promise-tracker";
import logo from "../../Assets/images/logo-dark.png";
import { setNewPassword } from "../../Services/UsermanagementServices";
import { ManageLocalStorage } from "../../Services/ManageLocalStorage";

const Newpassword = (props) => {
  const [submitted, setSubmitted] = React.useState(false);
  const [btnDisble, setBtnDisable] = React.useState(false);
  const[passwordChecking, setPasswordChecking] = React.useState(false);
  const [formObj, setFormObj] = React.useState({
    repassword: "",
    password: "",
  });
  const stateValue = useGlobalState().dispatch; //get the dispatch method from the useDispatch custom hook
  const dispatch = stateValue;
  const newPasswordSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (formObj.repassword &&formObj.password &&formObj.repassword === formObj.password) {
      setPasswordChecking(true);
      const password_check  = await password_validate(formObj.password);
      // console.log(password_check);
      if(password_check) {
        // setPassswordPatternCheck(false);
        setBtnDisable(true);
        setSubmitted(false);
        let payload = await setNewPassword(dispatch, formObj.password);
        props.history.push("/dashboard");
      }
      
    }
  };
  const [passwordPatternCheck, setPassswordPatternCheck] = React.useState(false);
  function password_validate(p) {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    setPassswordPatternCheck(re.test(p));
    return re.test(p);
  }
  const setInput = (event) => {
    const { name, value } = event.target;
    setFormObj({ ...formObj, [name]: value });
  };

  useEffect(() => {
    let userDetailsForNewTenant = ManageLocalStorage.get(
      "userDetailsForNewTenant"
    );
  });

  const [passwordShown, setPasswordShown] = React.useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = React.useState(false);

  const passwordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const confirmPasswordVisiblity = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true);
  };


  return (
    <>
      <div className="auth-wrapper w-100 h-100 d-flex align-items-center justify-content-center">
        <div className="auth-box  px-4 pb-5 pt-5 rounded">
          <form className="form-signin" onSubmit={(e) => newPasswordSubmit(e)}>
            <div className="text-center mb-4">
              <img className="mb-2" src={logo} alt="" />
              {/* <img  className="mb-2" src="" alt="Logo"/> */}
              <h1 className="h3 mb-3 font-weight-normal">New Password</h1>
            </div>

            <div className="form-floating mb-3 password-text-erap">
              {/* <input type="password" className="form-control" id="login_pwd" placeholder="Password" /> */}
              <InputTextWrapper
                id="login_pwd"
                type={passwordShown ? "text" : "password"}
                name="password"
                value={formObj.password}
                required
                onChange={(e) => setInput(e)}
                className="form-control"
                placeholder={TEXT_LABELS.loginModule.pwd}
              />
                <i onClick={passwordVisiblity}  className={passwordShown ? "pi pi-eye-slash" : "pi pi-eye"}></i>
                <label htmlFor="login_pwd">
                {TEXT_LABELS.passwordModule.password}
              </label>
              

              {submitted && !formObj.password
                ? showFormValidation(
                    true,
                    true,
                    TEXT_LABELS.loginModule.required.pwd
                  )
                : showFormValidation(false)}

              
            </div>

            <div className="form-floating mb-3 password-text-erap">
              <InputTextWrapper
                id="login_repassword"
                name="repassword"
                type={confirmPasswordShown ? "text" : "password"}
                value={formObj.repassword}
                required
                className="form-control"
                onChange={(e) => setInput(e)}
                placeholder={TEXT_LABELS.passwordModule.repassword}
              />
              <i onClick={confirmPasswordVisiblity}  className={confirmPasswordShown ? "pi pi-eye-slash" : "pi pi-eye"}></i>
              <label htmlFor="login_repassword">
                {TEXT_LABELS.passwordModule.repassword}{" "}
              </label>

              {submitted && !formObj.repassword
                ? showFormValidation(
                    true,
                    true,
                    TEXT_LABELS.loginModule.required.repassword
                  )
                : showFormValidation(false)}
              {submitted && formObj.password !== formObj.repassword
                ? showFormValidation(
                    true,
                    true,
                    "Password not matching"
                  )
                : showFormValidation(false)}

              
            </div>
            <span>
                {
                  passwordChecking && !passwordPatternCheck ? showFormValidation(true, true, "The password must contain at least 8 characters including UPPER/lowercase and a number and a special character")
                  : ''
                }
            </span>

            {/* <ButtonWrapper
              className="btn btn-lg btn-primary btn-block w-100 login-brn"
              label={TEXT_LABELS.userManagementModule.submit}
              onClick={(e) => newPasswordSubmit(e)}
            /> */}
            <div className="p-field mt-3 diabled">
                <ButtonWrapper disabled={btnDisble}
                  className="p-button p-button-primary py-3 px-5"
                  label={TEXT_LABELS.userManagementModule.submit}
                  onClick={(e) => newPasswordSubmit(e)}
                />
              </div>
          </form>
        </div>
      </div>
    </>
  );
};

Newpassword.propTypes = {
  onLoginClick: PropTypes.func.isRequired,
  forgotPasswordOnClick: PropTypes.func,
};

export default Newpassword;
