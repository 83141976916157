import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { listTopics,deleteTopic,  } from "../../Services/CommonServices";
import WithLayout from '../../Shared/Components/HeaderFooterHOC';
import loader from "../../Assets/images/loader-1.png";
import DialogBox from '../../Shared/Components/DialogBox';


const TopicContainer = (props) => {

    const history = useHistory();
    // to redirect corresponding stroy
    const redirect = (rowData) => {
        history.push(`/topic/${rowData.id}`);
    }


    const [topicList, setTopicList] = useState([]);
    const [rowClickData, setRowClickData] = useState();
    const [showLoader, setLoader] = useState(false);

    const [displayDialog, setDisplayDialog] = React.useState({
        delete: false,
    });

    useEffect(() => {
        getTopics();
        return () => {
            // componentWillUnmount events
        }
    }, [])

    // get all topics
    const getTopics = () => {
        setLoader(true);
        listTopics()
            .then((res) => {
                const arr = res.data.topic_list;
                arr.map(item => {
                    item.launch_date = new Date(item.launch_date).toString();
                });
                setTopicList(arr);
                setLoader(false);
            })
            .catch(err => {
                setLoader(false);
                props.showToast("error", "Error", "Error fetching topics");
            })
    }

    // enable or disable dialog box
    const showDialog = (type, show) => {

        if (type === "delete") {
            setDisplayDialog((preval) => ({ ...preval, delete: show }));
            //   setLoader(false);
        }
    };

    // when add button clicked
    const addTenantOnClick = () => {
        props.history.push('/addTopic');
    };

    // when edit button clicked
    const editTopic = (rowData) => {
        props.history.push(`/editTopic/${rowData.id}`);
        // window.open(`/editTopic/${rowData.id}`, "_blank");
    }

    // when delete button clicked
    const confirmDeleteTopic = (rowData) => {
        setRowClickData({ ...rowData })
        showDialog("delete", true);
    }

    // to hide delete mmodal
    const hideDeleteProductDialog = () => {
        showDialog("delete", false);
    };

    // delete selected topic
    const deleteProduct = async () => {
        showDialog("delete", false);
        setLoader(true)
        deleteTopic(rowClickData.id)
            .then((res) => {
                if (res) {
                    getTopics();
                    props.showToast("success", "Success", "Topic deleted");
                    setLoader(false)
                }
            })
            .catch(err => {
                if (err) { console.log(err) }
                // console.log(err.response);
                props.showToast("error", "Error", "Error deleting topic");
                setLoader(false)
            });
    };

    // table action list area (redirect to story, edit, delete icons)
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/* <Button label="Story" icon="pi pi-reply" className="p-button-info p-mr-2" onClick={() => redirect(rowData)}>
                </Button> */}

                <Button icon="pi pi-reply" className="p-button-rounded p-button-outlined p-button-secondary p-mr-2" label="Story" onClick={() => redirect(rowData)} />

                <Button
                    icon="pi pi-pencil" title="Edit"
                    className="p-button-rounded p-button-primary p-button-outlined p-mr-2"
                    onClick={() => editTopic(rowData)}
                />
                <Button
                    icon="pi pi-trash" title="Delete"
                    className="p-button-rounded p-button-danger  p-button-outlined"
                    onClick={() => confirmDeleteTopic(rowData)}
                />
            </React.Fragment>
        );
    };

    // table ui
    return (

        <div>
            <div className="loader-overlay" style={{ display: showLoader ? "flex" : "none" }} >
                <div className="p-d-flex p-flex-column p-ai-center">
                    <div className="loader">
                        <img src={loader} width="100" height="100" />
                    </div>
                    <span className="loading-text p-mt-2">
                        Loading... Please Wait...
                    </span>
                </div>
            </div>


            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5 className="mb-0">
                    Topics
                </h5>
                <Button label="Create Topic" className="p-button p-component p-button-rounded p-px-4" icon="pi pi-plus" onClick={addTenantOnClick} />
            </div>

            <div>
                {displayDialog.delete && (
                    <div>
                        {/* <Dialog visible={displayDialog.delete} style={{ width: "450px" }} header="Confirm" modal
                            footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}
                        >
                            <div className="confirmation-content d-flex align-items-center">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                                {
                                    <span>
                                        Are you sure you want to delete <b>{rowClickData.topic_name}</b>?
                        </span>
                                }
                            </div>
                        </Dialog> */}
                        <DialogBox area="topic" type="delete" showDialog={displayDialog.delete} 
                                    hideDialog={hideDeleteProductDialog} product={rowClickData}
                                    confirmClick={()=>deleteProduct()} />
                    </div>
                )}
            </div>
            <div className="card">
                <DataTable value={topicList} removableSort paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={5} rowsPerPageOptions={[5, 10, 20, 50]} >
                    {/* <Column field="" header="No" sortable></Column> */}
                    <Column field="topic_name" header="Topic Name" sortable></Column>
                    <Column field="task_name" header="Task Name" sortable></Column>
                    <Column field="description" header="Description" sortable></Column>
                    {/* <Column field="launch_date" header="Launch Date" sortable></Column> */}
                    <Column header="Action" body={actionBodyTemplate} style={{ width: "210px" }} ></Column>
                    {/* <Column ></Column> */}
                </DataTable>
            </div>
        </div>
    )
}


export default WithLayout(TopicContainer)