import React from "react";
import { logout, useGlobalState } from "../../Context";
import { userRoles } from "../../Utilities/Utils";

function Header(props) {

  const stateValue = useGlobalState();
  
  let role;
  if(stateValue.user.userRole) {
    role = stateValue.user.userRole;
  }

  let userName;
  if (stateValue.user.attributes) {

    // console.log("stateValue", stateValue.user.attributes.name);
    userName = stateValue.user ? stateValue.user.attributes.name : "";

  } else {
    props.history.push("/login");
  }

  const logOutOnClick = async () => {
    //get the dispatch method from the useDispatch custom hook
    const dispatch = stateValue.dispatch;
    await logout(dispatch);
    props.history.push("/login");
  };

  const handleSidebarToggle = () => {
    const wrapperClass = document.getElementById("wrapper");
    if (wrapperClass.className === "d-flex main-container") {
      document.getElementById("wrapper").className =
        "d-flex main-container toggled";
    } else {
      document.getElementById("wrapper").className = "d-flex main-container";
    }
  };

  const profileDropDownToggle = () => {
    const profileClass = document.getElementById("profileDropDown");
    if (profileClass.className === "dropdown-menu") {
      document.getElementById("profileDropDown").className =
        "dropdown-menu px-2 show";
    } else {
      document.getElementById("profileDropDown").className = "dropdown-menu";
    }
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light px-2">
        <div className="d-flex align-items-center">
          <button className="btn" id="menu-toggle" onClick={handleSidebarToggle}>
            <i className="pi pi-bars"></i>
            {/* <span className="icon icon-dehaze"></span> */}
          </button>
          {
            role === 'super_admin' ? <h6 className="mb-0">Super Admin</h6> : <h6 className="mb-0">Tenant Admin</h6>
          }
          {/* <h6 className="mb-0">Tenant Admin</h6> */}
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="nav navbar-nav navbar-link-area ms-auto mt-2 mt-lg-0 align-items-center">
            {/* <li className="dropdown notifications-menu">
              <a
                href="#"
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="icon icon-notifications_none"></span>
                <span className="badge bg-danger">10</span>
              </a>
            </li> */}
            <li className="dropdown user user-menu">
              {/* <a
                href="#"
                // className="dropdown-toggle"
                // data-bs-toggle="dropdown"
                // aria-expanded="false"
                // onClick={profileDropDownToggle}
              > */}
              <div className="d-flex align-items-center">
                <div className="user-image d-flex align-items-center justify-content-center">
                  <span className="icon icon-account_circle me-0"></span>
                </div>
                <span className="hidden-xs me-1">{userName}</span>
              </div>
              {/* </a> */}
              {/* <div id="profileDropDown" className="dropdown-menu px-3">
                <span onClick={logOutOnClick} className="cursor-pointer d-block">
                  Logout
                </span>
              </div> */}
            </li>
            <li>
              <div onClick={logOutOnClick} className="cursor-pointer">
                <i className="pi pi-sign-out"></i>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}
export default Header;
