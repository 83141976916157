import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
// import '../src/Assets/scss/variables.scss';
import App from "./App";
//import reportWebVitals from "./reportWebVitals";
import AmplifyConfig from "./Utilities/AmplifyConfig";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";

import "./index.scss";
import "../src/Assets/css/common.scss";

AmplifyConfig();

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
