const GetConfigAWS = () => {
  let retVal = {};
  switch (process.env.REACT_APP_ENV) {
    case "develop":
      retVal = {
        apiGateway: {
          NAME: "TorusGames",
          REGION: "ap-south-1",
          URL: "https://8jwzuc32y3.execute-api.ap-south-1.amazonaws.com/develop/",
        },
        cognito: {
          REGION: "ap-south-1",
          USER_POOL_ID: "ap-south-1_a1xYDmiw3",
          APP_CLIENT_ID: "2ld9o8vhiingtjmbdi71qu44fg",
          IDENTITY_POOL_ID: "ap-south-1:b9cf2289-82ea-4886-b3f2-bcb9f651764e",
          UserPoolId: "ap-south-1_a1xYDmiw3",
          ClientId: "2ld9o8vhiingtjmbdi71qu44fg",
        },
        BASE_URL: 'https://cc-files-develop.s3.ap-south-1.amazonaws.com/'
      };
      break;
    case "qa":
      retVal = {
        apiGateway: {
          NAME: "TorusGames",
          REGION: "ap-south-1",
          URL: "https://ji25wwkmtd.execute-api.ap-south-1.amazonaws.com/qa/",
        },
        cognito: {
          REGION: "ap-south-1",
          USER_POOL_ID: "ap-south-1_zMygDlIFt",
          APP_CLIENT_ID: "6onhgs630dsasubth2ph06cchd",
          IDENTITY_POOL_ID: "ap-south-1:6e5d82dc-5f61-41da-adbc-91948fbf96ba",
          UserPoolId: "ap-south-1_zMygDlIFt",
          ClientId: "6onhgs630dsasubth2ph06cchd",
        },
        BASE_URL: 'https://cc-files-qa.s3.ap-south-1.amazonaws.com/'
      };
      break;
    case "stage":
      retVal = {
        apiGateway: {
          NAME: "TorusGames",
          REGION: "us-east-1",
          URL: "https://6z958ikjr8.execute-api.us-east-1.amazonaws.com/stage",
        },
        cognito: {
          REGION: "us-east-1",
          USER_POOL_ID: "us-east-1_YVfptXORd",
          APP_CLIENT_ID: "373v7j7u2q0346mh7drlt3jsfp",
          IDENTITY_POOL_ID: "us-east-1:0c16ba09-55aa-4390-b470-5c7b10c4af29",
          UserPoolId: "us-east-1_YVfptXORd",
          ClientId: "373v7j7u2q0346mh7drlt3jsfp",
        },
        BASE_URL: 'https://cc-files-stage.s3.amazonaws.com/'
      };
      break;
    case "prod":
      retVal = 
      // {
      //   apiGateway: {
      //     NAME: "TorusGames",
      //     REGION: "us-east-1",
      //     URL: "https://jose5khoi1.execute-api.us-east-1.amazonaws.com/production/",
      //   },
      //   cognito: {
      //     REGION: "eu-central-1",
      //     USER_POOL_ID: "us-east-1_YVfptXORd",
      //     APP_CLIENT_ID: "373v7j7u2q0346mh7drlt3jsfp",
      //     IDENTITY_POOL_ID: "us-east-1:0c16ba09-55aa-4390-b470-5c7b10c4af29",
      //     UserPoolId: "us-east-1_YVfptXORd",
      //     ClientId: "373v7j7u2q0346mh7drlt3jsfp",
      //   },
      //   BASE_URL: 'https://cc-files-production.s3.amazonaws.com/'
      // }
      {
        apiGateway: {
          NAME: "TorusGames",
          REGION: "us-east-1",
          URL: "https://abo0skkcge.execute-api.us-east-1.amazonaws.com/production/",
        },
        cognito: {
          REGION: "us-east-1",
          USER_POOL_ID: "us-east-1_OwPC1tdE3",
          APP_CLIENT_ID: "6kc8jj7kpo9ft3omi6it6fted0",
          IDENTITY_POOL_ID: "us-east-1:d215b059-4072-4f36-a71b-22f6ebffdbe6",
          UserPoolId: "us-east-1_OwPC1tdE3",
          ClientId: "6kc8jj7kpo9ft3omi6it6fted0",
        },
        BASE_URL: 'https://cc-files-production.s3.amazonaws.com/',
      };
      // {
      //   apiGateway: {
      //     NAME: "TorusGames",
      //     REGION: "us-east-1",
      //     URL: "http://0.0.0.0:5500/",
      //   },
      //   cognito: {
      //     REGION: "us-east-1",
      //     USER_POOL_ID: "us-east-1_OwPC1tdE3",
      //     APP_CLIENT_ID: "6kc8jj7kpo9ft3omi6it6fted0",
      //     IDENTITY_POOL_ID: "us-east-1:d215b059-4072-4f36-a71b-22f6ebffdbe6",
      //     UserPoolId: "us-east-1_OwPC1tdE3",
      //     ClientId: "6kc8jj7kpo9ft3omi6it6fted0",
      //   },
      //   BASE_URL: "http://0.0.0.0:5500/",
      // };
      break;
    default:
      break;
  }
  return retVal;
};

export default GetConfigAWS;
