import React from "react";
import { useGlobalState } from "../../Context";
import WithLayout from "../../Shared/Components/HeaderFooterHOC";
import { userRoles } from "../../Utilities/Utils";
import SuperAdminDashboard from "./SuperAdminDashboard";
import TenantAdminDashboard from "./TenantAdminDashboard";

const DashboardContainer = (props) => {
  const stateValue = useGlobalState();
  const userDetails = stateValue.user;

  return (
    <>
      {userDetails.userRole === userRoles.SuperAdmin ? (
        <SuperAdminDashboard {...props} />
      ) : (
        <TenantAdminDashboard {...props} />
      )}
    </>
  );
};

export default WithLayout(DashboardContainer);
