import React, {useEffect, useState} from "react";
import styles from "../../Assets/css/dashboard.module.scss";
import ButtonWrapper from "../../Shared/Components/Button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { listTopics, userList } from '../../Services/CommonServices'

function TenantAdminDashboard(props) {

  const [users, setUsers] = useState([]);


  useEffect(() => {

    // listTopics()
    // .then(res=>{
    //   console.log(res.response)
    // })
    // .catch(err =>{
    //   console.log(err);
    // })

    userList().then((list) => {
      // console.log(list)
      setUsers(list.data);

    })
    .catch(err=>{
      console.log(err);
    })
    
    return () => {
      
    }
  }, [])

  return (
    <div className="dashboard-wrap tenant-admin">
      {/* <div className={styles.dashboardPage}>
        <h2 className="main-heading">
          Tenant Admin Dashboard
          {/* <ButtonWrapper
            label="Settings"
            icon="pi pi-cog"
            className="p-button p-component p-button-sm settings-button"
            onClick={() => props.history.push("/admin-settings")}
          /> 
        </h2>
      </div> */}

      <div className="dashboard-info">
        <div className="row dashboard-info-cards-row">
          <div className="col col-md-4">
            <div className="card dashboard-card d-flex align-items-center p-3">
              <div className="card-icon mb-2">
                <i className="pi pi-users"></i>
              </div>
              <div className="card-data">
                {/* <div className="d-flex"> */}
                  <div className="card-value active text-center">
                    <span className="card-val ">{users ? users.length : '0'}</span> 
                    {/* <span className="card-val-label">Active</span> */}
                  </div>
                  {/* <div className="card-value inactive">
                    <span className="card-val">5</span> <span className="card-val-label">Inactive</span>
                  </div> */}
                </div>
                <div className="card-label">
                  No. of Registered Users
                </div>

              {/* </div> */}

            </div>
          </div>
          <div className="col col-md-4">
            <div className="card dashboard-card d-flex align-items-center p-3">
              <div className="card-icon mb-2">
                <i className="pi pi-book"></i>
              </div>
              <div className="card-data">
                <div className="card-value text-center">
                  24
                </div>
                <div className="card-label">
                  No. of Topic Available - <strong>Experience</strong>
                </div>

              </div>

            </div>
          </div>
          <div className="col col-md-4">
            <div className="card dashboard-card d-flex align-items-center p-3">
              <div className="card-icon mb-2">
                <i className="pi pi-book"></i>
              </div>
              <div className="card-data">

                <div className="card-value text-center">
                  38
                </div>
                <div className="card-label">
                  No. of Topic Available - <strong>Conversation</strong>
                </div>

              </div>

            </div>
          </div>


        </div>

        <div className="row mt-3">
          <div className="col-md-8">
            <div className="card">
              <div className="card-header px-3 py-2">

                <strong>Recent Users</strong>
              </div>
              <div className="card-body py-2 px-3 user-card-scroll">
                <DataTable value={users} removableSort>
                  <Column
                    field="phone_number"
                    header="Phone Number"
                  ></Column>
                  <Column field="email" header="Email"></Column>
                  <Column field="nickname" header="Nick name"></Column>
                  <Column field="gender" header="Gender"></Column>
                  {/* <Column header="Actions" body={actionBodyTemplate}></Column> */}
                </DataTable>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-header px-3 py-2">

                <strong>Latest Topics</strong>
              </div>
              <div className="card-body py-2 px-3">

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TenantAdminDashboard;
