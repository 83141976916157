import { Auth } from "aws-amplify";
import axios from "axios";
import GetConfigAWS from "../GetAWSConfig";
import { tenantAPIEndpoints } from "./APIEndpoints";
import { AmplifyAPI } from "./AmplifyAPI";
const ConfigAWS = GetConfigAWS();

export const loadTenantList = async (userDetails) => {
  const session = await Auth.currentSession();
  return axios.get(`${ConfigAWS.apiGateway.URL}/admin/tenant-list`, {
    headers: {
      Authorization: session.idToken.jwtToken,
      "Content-Type": "application/json",
    },
  });
};
// function uuidv4() {
//   return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
//     var r = (Math.random() * 16) | 0,
//       v = c == "x" ? r : (r & 0x3) | 0x8;
//     return v.toString(16);
//   });
// }
export const uuidv4 = ()=>{
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
export const createTenant = (body) =>
  AmplifyAPI("post", tenantAPIEndpoints.create(), { body }, true);

export const updateTenant = async (id, updateData) => {

  const topicIds = [];
  updateData.topics_selected.map((adtl) => {
    topicIds.push(adtl.code);
  });

  const reqObj = {
    tenant_name: updateData.tenant_name,
    email: updateData.email,
    domain_name: updateData.domain_name,
    topic_list: topicIds,
    nickname: "NA",
    gender: "NA",
    contact_person_name : updateData.contact_person_full_name,
    company_website: updateData.company_website,
    address_line1: updateData.address_line1,
    address_line2: updateData.address_line2,
    city_name: updateData.city_name,
    state_name: updateData.state_name,
    country_name: updateData.country_name,
    zip_code: updateData.zip_code,
    no_of_employees: updateData.no_of_employees,
    expected_nos: updateData.expected_nos,
    description: updateData.description,
    phone_number: updateData.contact_person_no,
    "custom:user_role": "tenant_admin",
    "custom:app": "admin_user",
  };

  // console.log(reqObj);

  const session = await Auth.currentSession();
  return axios.put(`${ConfigAWS.apiGateway.URL}/admin/tenant/` + id, reqObj, {
    headers: {
      Authorization: session.idToken.jwtToken,
      "Content-Type": "application/json",
    },
  });
};

export const deleteTenant = async (id,type) => {
  const session = await Auth.currentSession();
  return axios.delete(`${ConfigAWS.apiGateway.URL}/admin/tenant/${id}?action=${type}`, {
    headers: {
      Authorization: session.idToken.jwtToken,
      "Content-Type": "application/json",
    },
  });
};

export const addTenant = async (signUpData) => {
  // try {
    const session = await Auth.currentSession();
    const topicIds = [];
    signUpData.topics_selected.map((adtl) => {
      topicIds.push(adtl.code);
    });
    const reqObj = {
      tenant_name: signUpData.tenant_name,
      email: signUpData.email,
      domain_name: signUpData.domain_name,
      topic_list: topicIds,
      nickname: "NA",
      gender: "NA",
      contact_person_name : signUpData.contact_person_full_name,
      company_website: signUpData.company_website,
      address_line1: signUpData.address_line1,
      address_line2: signUpData.address_line2,
      city_name: signUpData.city_name,
      state_name: signUpData.state_name,
      country_name: signUpData.country_name,
      zip_code: signUpData.zip_code,
      no_of_employees: signUpData.no_of_employees,
      expected_nos: signUpData.expected_nos,
      description: signUpData.description,
      phone_number: signUpData.contact_person_no,
      "custom:user_role": "tenant_admin",
      "custom:app": "admin_user",
      active : signUpData.active
    };

    return axios.post(`${ConfigAWS.apiGateway.URL}/admin/tenant/`, reqObj, {
      headers: {
        Authorization: session.idToken.jwtToken,
        "Content-Type": "application/json",
      },
    });
  // } catch (e) {
  //   return e;
  // }
};

export const getTenant = async (id) => {
  const session = await Auth.currentSession();
  return axios.get(`${ConfigAWS.apiGateway.URL}/admin/tenant/${id}`, {
    headers: {
      Authorization: session.idToken.jwtToken,
      "Content-Type": "application/json",
    },
  });
};

export const userList = async () => {
  const session = await Auth.currentSession();
  return axios.get(`${ConfigAWS.apiGateway.URL}/tenant/user-list`, {
    headers: {
      Authorization: session.idToken.jwtToken,
      "Content-Type": "application/json",
    },
  });
};

export const updateUser = async (id, body) => {
  const session = await Auth.currentSession();
  return axios.put(
    `${ConfigAWS.apiGateway.URL}/tenant/user/` + id,
    {
      nickname: body.nickname,
      gender: body.gender,
    },
    {
      headers: {
        Authorization: session.idToken.jwtToken,
        "Content-Type": "application/json",
      },
    }
  );
};

export const deleteUser = async (id,type) => {
  const session = await Auth.currentSession();
  return axios.delete(
    `${ConfigAWS.apiGateway.URL}/tenant/user/${id}?action=${type}`,
    {
      headers: {
        Authorization: session.idToken.jwtToken,
        "Content-Type": "application/json",
      },
    }
  );

}

export const submitStory = async (body) => {};

export const listTopics = async () => {
  const session = await Auth.currentSession();
  return axios.get(`${ConfigAWS.apiGateway.URL}/admin/topic-list`, {
    headers: {
      Authorization: session.idToken.jwtToken,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "http://localhost:3000",
    },
  });
};

export const getCoaches = async () => {
  const session = await Auth.currentSession();
  return axios.get(`${ConfigAWS.apiGateway.URL}/admin/coach-list`, {
    headers: {
      Authorization: session.idToken.jwtToken,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "http://localhost:3000",
    },
  });
};

export const createTopic = async (formObj) => {
  const session = await Auth.currentSession();
  return axios.post(`${ConfigAWS.apiGateway.URL}/admin/topic`, formObj, {
    headers: {
      Authorization: session.idToken.jwtToken,
      "Content-Type": "application/json",
    },
  });
};

export const getTopic = async (id) => {
  const session = await Auth.currentSession();
  return axios.get(`${ConfigAWS.apiGateway.URL}/admin/topic/${id}`, {
    headers: {
      Authorization: session.idToken.jwtToken,
      "Content-Type": "application/json",
    },
  });
};

export const updateTopic = async (id, formObj) => {
  const session = await Auth.currentSession();
  return axios.put(`${ConfigAWS.apiGateway.URL}/admin/topic/${id}`, formObj, {
    headers: {
      Authorization: session.idToken.jwtToken,
      "Content-Type": "application/json",
    },
  });
};

export const deleteTopic = async (id) => {
  const session = await Auth.currentSession();
  return axios.delete(`${ConfigAWS.apiGateway.URL}/admin/topic/${id}`, {
    headers: {
      Authorization: session.idToken.jwtToken,
      "Content-Type": "application/json",
    },
  });
};

export const listStoryByTopicId = async (id) => {
  const session = await Auth.currentSession();
  return axios.get(`${ConfigAWS.apiGateway.URL}/admin/topic/${id}`, {
    headers: {
      Authorization: session.idToken.jwtToken,
      "Content-Type": "application/json",
    },
  });
};

export const getStory = async (storyId, topicId) => {
  const session = await Auth.currentSession();
  return axios.get(
    `${ConfigAWS.apiGateway.URL}/admin/story/${storyId}?topic_id=${topicId}`,
    {
      headers: {
        Authorization: session.idToken.jwtToken,
        "Content-Type": "application/json",
      },
    }
  );
};

export const fileUpload = async (formData) => {
  // console.log(formData);
  const session = await Auth.currentSession();
  return axios.post(`${ConfigAWS.apiGateway.URL}/admin/upload`, formData, {
    headers: {
      Authorization: session.idToken.jwtToken,
      "content-type": "application/json",
    },
  });
};

export const createStory = async (formObj) => {
  const session = await Auth.currentSession();
  return axios.post(`${ConfigAWS.apiGateway.URL}/admin/story`, formObj, {
    headers: {
      Authorization: session.idToken.jwtToken,
      "Content-Type": "application/json",
    },
  });
};

export const updateStory = async (formObj, id) => {
  const opt=formObj.options.map((q)=>{return{M:q}})
  formObj.options=JSON.stringify(opt)
  // console.log('form',formObj)
  const session = await Auth.currentSession();
  return axios.put(`${ConfigAWS.apiGateway.URL}/admin/story/${id}`, formObj, {
    headers: {
      Authorization: session.idToken.jwtToken,
      "Content-Type": "application/json",
    },
  });
};
export const deleteStory = async (storyId, topicId) => {
  const session = await Auth.currentSession();
  return axios.delete(
    `${ConfigAWS.apiGateway.URL}/admin/story/${storyId}?topic_id=${topicId}`,
    {
      headers: {
        Authorization: session.idToken.jwtToken,
        "Content-Type": "application/json",
      },
    }
  );
};

// Get superadmin analytics
export const listAnalytics = async () => {
  const session = await Auth.currentSession();
  return axios.get(`${ConfigAWS.apiGateway.URL}/admin/user-list`, {
    headers: {
      Authorization: session.idToken.jwtToken,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "http://localhost:3000",
    },
  });
};

// Get library list
export const listLibraryItems = async () => {
  const session = await Auth.currentSession();
  return axios.get(`${ConfigAWS.apiGateway.URL}/admin/lib-list`, {
    headers: {
      Authorization: session.idToken.jwtToken,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "http://localhost:3000",
    },
  });
};

// Get library item
export const getLibraryItem = async (id) => {
  const session = await Auth.currentSession();
  return axios.get(`${ConfigAWS.apiGateway.URL}/admin/library/${id}`, {
    headers: {
      Authorization: session.idToken.jwtToken,
      "Content-Type": "application/json",
    },
  });
};

// Create library item
export const createLibraryItem = async (formObj) => {
  const session = await Auth.currentSession();
  return axios.post(`${ConfigAWS.apiGateway.URL}/admin/library`, formObj, {
    headers: {
      Authorization: session.idToken.jwtToken,
      "Content-Type": "application/json",
    },
  });
};

// Update library item
export const updateLibraryItem = async (id, formObj) => {
  const session = await Auth.currentSession();
  return axios.put(`${ConfigAWS.apiGateway.URL}/admin/library/${id}`, formObj, {
    headers: {
      Authorization: session.idToken.jwtToken,
      "Content-Type": "application/json",
    },
  });
};

// Delete library item
export const deleteLibraryItem = async (id) => {
  const session = await Auth.currentSession();
  return axios.delete(`${ConfigAWS.apiGateway.URL}/admin/library/${id}`, {
    headers: {
      Authorization: session.idToken.jwtToken,
      "Content-Type": "application/json",
    },
  });
};