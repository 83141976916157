import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from 'primereact/inputswitch';
import {
  loadTenantList,
  deleteTenant,
} from "../../Services/CommonServices";
import { userRoles } from "../../Utilities/Utils";
import WithLayout from "../../Shared/Components/HeaderFooterHOC";
import { useGlobalState } from "../../Context";
import loader from "../../Assets/images/loader-1.png";
import DialogBox from "../../Shared/Components/DialogBox";

const TenantContainer = (props) => {

  let emptyProduct = {
    id: null,
    name: "",
    email: "",
  };

  const [showLoader, setLoader] = useState(false);
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(emptyProduct);

  const [displayDialog, setDisplayDialog] = React.useState({
    delete: false,
    status: false
  });

  useEffect(() => {
    setLoader(true);
    loadTenantList(userDetails).then((list) => {
      setProducts(list.data.tenant_list);

      setTimeout(() => {
        setLoader(false);
      }, 200);

    });

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // check admin role
  const stateValue = useGlobalState();
  const userDetails = stateValue.user;

  if (userDetails.userRole !== userRoles.SuperAdmin) {
    props.history.push("/dashboard");
  }

  // add tenant routing
  const addTenantOnClick = () => {
    props.history.push('/addTenant');
  };

  // edit tenant routing
  const editTenant = (tenantData) => {
    props.history.push(`/editTenant/${tenantData.id}`)
  };

  // enable delete confirmation modal
  const showDialog = (type, show) => {
    if (type === "delete") {
      setDisplayDialog((preval) => ({ ...preval, delete: show }));
    }
    if(type === "status") {
      setDisplayDialog((preval) => ({ ...preval, status: show }));
    }

  };
  
  // hide delete confirmation modal
  const hideDeleteProductDialog = () => {
    showDialog("delete", false);
  };

  // delete tenant list inital click
  const confirmDeleteTenant = (tenantData) => {
    setProduct(tenantData);
    showDialog("delete", true);
  };

  // delete tenant
  const deleteProduct = async () => {
    setLoader(true);
    deleteTenant(product.id,"delete")
      .then((res) => {
        if (res) {
          showDialog("delete", false);
          props.showToast("success", "Success", "Tenant deleted.");
          loadTenantList().then((list) => {
            setProducts(list.data.tenant_list);
            setLoader(false);
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          showDialog("delete", false);
          setLoader(false);
          props.showToast("error", "Error", error.response.data.message);
        }

      });
  };

  // table action items
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-primary p-button-outlined p-mr-2"
          onClick={() => editTenant(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger p-button-outlined"
          onClick={() => confirmDeleteTenant(rowData)}
        />
      </React.Fragment>
    );
  };

  //status 
  const statusBodyTemplate = (rowData) => {
    return (
      <InputSwitch checked={rowData.active} onChange={(e)=>statusModal(rowData, e.value)}/>
    )
  }

  const [statusType, setStatusType] = useState('');

  const statusModal = (rowData,value) => {
    setProduct(rowData);
    showDialog("status", true);
    value ? setStatusType('enable') : setStatusType('disable')
  }

  // hide delete confirmation modal
  const hideStatusProductDialog = () => {
    showDialog("status", false);
  };

  const setStatus = (value) => {

    hideStatusProductDialog();
    setLoader(true);

    if(value === 'enable') {
      deleteTenant(product.id,"enable")
      .then(res=>{
          props.showToast("success", "Success", "Tenant Enabled.");
          loadTenantList().then((list) => {
            setProducts(list.data.tenant_list);
            setLoader(false);
          });
      })
      .catch(err=>{
        console.log(err);
        props.showToast("error", "Error", "Something went wrong !");
        setLoader(false);
      })

    } else {
      deleteTenant(product.id,"disable")
      .then(res=>{
          props.showToast("success", "Success", "Tenant Disabled.");
          loadTenantList().then((list) => {
            setProducts(list.data.tenant_list);
            setLoader(false);
            
          });
      })
      .catch(err=>{
        console.log(err);
        props.showToast("error", "Error", "Something went wrong !");
        setLoader(false);
      })

    }
  }

  // render
  return (
    <div>
      <div
        className="loader-overlay"
        style={{ display: showLoader ? "flex" : "none" }}
      >
        <div className="p-d-flex p-flex-column p-ai-center">
          <div className="loader">
            <img src={loader} width="100" height="100" />
          </div>
          <span className="loading-text p-mt-2">Loading... Please Wait...</span>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center mb-2">

        <h5 className="mb-0">Tenants</h5>

        <Button
          label="Create Tenant"
          className="p-button p-component p-button-rounded p-px-4"
          icon="pi pi-plus"
          onClick={addTenantOnClick}
        ></Button>
      </div>

      <div>
        {displayDialog.status && (
          <div>
            <DialogBox area="tenant" type={statusType} showDialog={displayDialog.status} 
                        hideDialog={hideStatusProductDialog} product={product}
                        confirmClick={()=>setStatus(statusType)}/>
          </div>          
        )}
      </div>

      <div>
        {displayDialog.delete && (
          <div>
          <DialogBox area="tenant" type="delete" showDialog={displayDialog.delete} 
                      hideDialog={hideDeleteProductDialog} product={product}
                      confirmClick={()=>deleteProduct()}/>
        </div>
        )}
      </div>

      <div className="card">
      <DataTable
        value={products}
        removableSort
        paginator
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        rows={5}
        rowsPerPageOptions={[5, 10, 20, 50]}
      >
        {/* <Column field="id" header="Id" sortable></Column> */}
        <Column field="tenant_name" header="Tenant Name" sortable></Column>
        <Column field="domain_name" header="Domain Name" sortable></Column>
        <Column field="company_website" header="Website" sortable></Column>
        <Column field="email" header="Email" sortable></Column>
        <Column field="phone_number" header="Contact Number" sortable></Column>                
        {/* <Column field="type" header="Type" sortable></Column> */}
        <Column body={statusBodyTemplate} header="Status"></Column>
        <Column  header="Action" body={actionBodyTemplate}style={{ width: "120px" }}></Column>
      </DataTable>
      </div>
    </div>
  );
};

export default WithLayout(TenantContainer);
