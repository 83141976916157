import DashboardContainer from "../Modules/Dashboard/DashboardContainer";
import LoginContainer from "../Modules/Login/LoginContainer";
import UserManagementContainer from "../Modules/UserManagement/UserManagementContainer";
import StoryContainer from "../Modules/Story/StoryContainer";
import UserContainer from "../Modules/User/UserContainer";
import TenantContainer from "../Modules/Tenant/TenantContainer";
import TopicContainer from "../Modules/Topics/TopicContainer";
import Newpassword from "../Modules/Login/Newpassword";
import { userRoles } from "../Utilities/Utils";
import AddTenant from "../Modules/Tenant/AddTenant";
import AddTopic from "../Modules/Topics/AddTopic";
import AnalyticsContainer from "../Modules/Analytics/AnalyticsContainer";
import LibraryContainer from "../Modules/Library/LibraryContainer";
import AddLibrary from "../Modules/Library/AddLibrary";
const { SuperAdmin, TenantAdmin } = userRoles;
const routes = [
  {
    path: "/login",
    component: LoginContainer,
    isPrivate: false,
  },
  {
    path: "/dashboard",
    component: DashboardContainer,
    isPrivate: false,
    permissions: [SuperAdmin, TenantAdmin],
  },
  {
    path: "/tenant",
    component: TenantContainer,
    isPrivate: false,
    permissions: [SuperAdmin],
  },
  {
    path: "/addTenant",
    component: AddTenant,
    isPrivate: false,
    permissions: [SuperAdmin],
  },
  {
    path: "/editTenant/:id",
    component: AddTenant,
    isPrivate: false,
    permissions: [SuperAdmin],
  },
  {
    path: "/resetpassword",
    component: UserManagementContainer,
    isPrivate: false,
  },
  {
    path: "/newpassword",
    component: Newpassword,
    isPrivate: false,
  },
  {
    path: "/topics",
    component: TopicContainer,
    isPrivate: false,
    permissions: [SuperAdmin],
  },
  {
    path: "/addTopic",
    component: AddTopic,
    isPrivate: false,
    permissions: [SuperAdmin],
  },
  {
    path: "/editTopic/:id",
    component: AddTopic,
    isPrivate: false,
    permissions: [SuperAdmin],
  },
  {
    path: "/topic/:id",
    component: StoryContainer,
    isPrivate: false,
    permissions: [SuperAdmin],
  },
  {
    path: "/analytics",
    component: AnalyticsContainer,
    isPrivate: false,
    permissions: [SuperAdmin],
  },
  {
    path: "/library",
    component: LibraryContainer,
    isPrivate: false,
    permissions: [SuperAdmin],
  },
  {
    path: "/addLibrary",
    component: AddLibrary,
    isPrivate: false,
    permissions: [SuperAdmin],
  },
  {
    path: "/editLibrary/:id",
    component: AddLibrary,
    isPrivate: false,
    permissions: [SuperAdmin],
  },
  {
    path: "/user",
    component: UserContainer,
    isPrivate: false,
  },
  {
    path: "/",
    component: "",
    isPrivate: false,
  },
];

export default routes;
