import { Auth } from "aws-amplify";
import { ManageLocalStorage } from "../Services/ManageLocalStorage";
export const actions = {
  sendCode: "Send Code",
  resetPwd: "Reset Password",
};
const sendCode = (email) =>
  Auth.forgotPassword(email).then((response) => response);
const resetPwd = (email, code, Password) =>
  Auth.forgotPasswordSubmit(email, code, Password).then((response) => response);

export const forgotPwdSendCode = async (email) => {
  return await sendCode(email);
};
export const forgotPwd = async (formObj) => {
  try {
    return await resetPwd(formObj.email, formObj.code, formObj.password);
  } catch (e) {
    throw e;
  }
};

export const setNewPassword = async (dispatch, newPassword) => {
  let username = ManageLocalStorage.get("username");
  let password = ManageLocalStorage.get("password");

  return Auth.signIn(username, password).then(async (user) => {
    if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
      return Auth.completeNewPassword(
        user, // the Cognito User Object
        newPassword
      ).then(async (userR) => {
        // console.log(userR);
        return Auth.signIn(username, newPassword).then(async (user) => {
          dispatch({
            type: "LOGIN_SUCCESS",
            payload: user,
          });
        });
      }).catch(err => {
        console.log(err);
      })
      ;
    }
  });
};
