import React from "react";
import PropTypes from "prop-types";

import "../../Assets/css/login.module.scss";
import { TEXT_LABELS } from "../../Utilities/TextLabels";
import InputTextWrapper from "../../Shared/Components/InputText";
import ButtonWrapper from "../../Shared/Components/Button";
import { showFormValidation } from "../../Utilities/Utils";
import { trackPromise } from "react-promise-tracker";

import logo from "../../Assets/images/logo-dark.png";

const Login = (props) => {
  const { forgotPasswordOnClick, onLoginClick } = props;
  const [submitted, setSubmitted] = React.useState(false);
  const [formObj, setFormObj] = React.useState({ username: "", password: "" });
  const [passwordShown, setPasswordShown] = React.useState(false);
  const onLoginSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (formObj.username && formObj.password) {
      trackPromise(onLoginClick(formObj.username, formObj.password));
    }
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const setInput = (event) => {
    const { name, value } = event.target;
    setFormObj({ ...formObj, [name]: value });
  };
  return (
    <>
      <div className="auth-wrapper w-100 h-100 d-flex align-items-center justify-content-center">
        <div className="auth-box  px-4 pb-5 pt-5 rounded">
          <form className="form-signin" onSubmit={(e) => onLoginSubmit(e)}>
            <div className="text-center mb-4">
              <img className="mb-2" src={logo} alt="" />
              {/* <img  className="mb-2" src="" alt="Logo"/> */}
              <h1 className="h3 mb-3 font-weight-normal">Sign In</h1>
            </div>
            <div className="form-floating mb-3">
              {/* <input type="email" className="form-control" id="login_username" placeholder="name@example.com" /> */}

              <InputTextWrapper
                id="login_username"
                name="username"
                type="text"
                value={formObj.username}
                required
                className="form-control"
                autoFocus
                onChange={(e) => setInput(e)}
                placeholder="Email"
              />
              {submitted && !formObj.username
                ? showFormValidation(
                    true,
                    true,
                    "Email required"
                  )
                : showFormValidation(false)}
              <label htmlFor="login_username">
                Email{" "}
              </label>
            </div>
            <div className="form-floating password-text-erap">
              {/* <input type="password" className="form-control" id="login_pwd" placeholder="Password" /> */}
              <InputTextWrapper
                id="login_pwd"
                type={passwordShown ? "text" : "password"}
                name="password"
                value={formObj.password}
                required
                onChange={(e) => setInput(e)}
                className="form-control"
                placeholder={TEXT_LABELS.loginModule.pwd}
              />
              <i onClick={togglePasswordVisiblity}  className={passwordShown ? "pi pi-eye-slash" : "pi pi-eye"}></i>
              {submitted && !formObj.password
                ? showFormValidation(
                    true,
                    true,
                    TEXT_LABELS.loginModule.required.pwd
                  )
                : showFormValidation(false)}
              <label htmlFor="login_pwd">{TEXT_LABELS.loginModule.pwd}</label>
            </div>
            <div className="d-flex my-2 justify-content-between align-items-center">
              <div className="form-check form-check-inline">
                {/* <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox2"
                  value="option2"
                /> */}
                {/* <label className="form-check-label" for="inlineCheckbox2">
                  Remember me
                </label> */}
              </div>

              <span
                className="anchor-text cursor-pointer"
                onClick={(e) => forgotPasswordOnClick(e)}
              >
                {TEXT_LABELS.loginModule.forgotPwd}
              </span>
            </div>

            <ButtonWrapper
              className="w-100 login-brn"
              label={TEXT_LABELS.loginModule.logIn}
              onClick={(e) => onLoginSubmit(e)}
            />
            {/* <a className="btn btn-lg btn-primary btn-block w-100" href="index.html">Sign in</a> */}
          </form>
          {/* <div className="text-center mt-3">
            Don't have an account? <a href="signup.html" className="anchor-text">Sign Up</a>
          </div> */}
        </div>
      </div>
    </>
  );
};

Login.propTypes = {
  onLoginClick: PropTypes.func.isRequired,
  forgotPasswordOnClick: PropTypes.func,
};

export default Login;
