import React from 'react';
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";


const DialogBox = (props) => {

    const dialogFooter = () => {
        return (
          <>
            <Button label="Yes" icon="pi pi-check" className="p-button-primary" onClick={props.confirmClick}/>
            <Button  label="No" icon="pi pi-times" className="p-button-secondary" onClick={props.hideDialog} />
          </>
        );
      }

    return (
        <>
            <Dialog visible={props.showDialog} style={{ width: "450px" }}
              header="Confirm" modal footer={dialogFooter} onHide={props.hideDialog} >
              <div className="confirmation-content d-flex align-items-center">
                <i className="pi pi-exclamation-triangle p-mr-3"style={{ fontSize: "2rem" }} />
                {
                  <span>
                    Are you sure you want to {props.type} <strong> 
                        {   props.area === "user" ? props.product.nickname 
                          : props.area === "tenant" ? props.product.tenant_name 
                          : props.area === "topic"  ? props.product.topic_name 
                          : props.area === "story" ? props.product.st_name
                          : null
                        } 
                    </strong> ?
                  </span>
                }
              </div>
            </Dialog>
        </>
    )
}

export default DialogBox
