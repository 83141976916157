import React from "react";
import { Link, NavLink } from "react-router-dom";

import logo from "../../Assets/images/logo.png";
import { useGlobalState } from "../../Context";
import { userRoles } from "../../Utilities/Utils";

function Sidebar(props) {
  const stateValue = useGlobalState();
  const userDetails = stateValue.user;
  let superAdminShow = false;
  let tenantAdminShow = false;
  if (userDetails.userRole === userRoles.SuperAdmin) {
    superAdminShow = true;
  }
  if (userDetails.userRole === userRoles.TenantAdmin) {
    tenantAdminShow = true;
  }

  const checkTenantSectionActive = () => {
    const pathname = props.match.path;
    return pathname === "/tenant" || pathname === "/addTenant" || pathname === "/editTenant/:id";
  }

  const checkTopicSectionActive = () => {
    const pathname = props.match.path;
    return pathname === "/topics" || pathname === "/addTopic" || pathname === "/editTopic/:id" || pathname === "/topic/:id";
  }

  const checkLibrarySectionActive = () => {
    const pathname = props.match.path;
    return pathname === "/library" || pathname === "/addLibrary" || pathname === "/editLibrary/:id";
  }

  return (
    <div className="border-right sidebar" id="sidebar-wrapper">
      <div className="sidebar-heading py-2 px-3 d-flex align-items-center justify-content-center">
        <div className="logo">
          <img src={logo} alt="logo"/>
        </div>
      </div>
      <div className="list-group text list-group-flush mt-4">
        <NavLink activeclassname="side-active"
          className="list-group-item list-group-item-action d-flex flex-column align-items-center"
          to="/dashboard"
        >

          <i className="pi pi-home"></i>
          Dashboard
        </NavLink>

        <NavLink activeclassname="side-active"  isActive={checkTenantSectionActive}
          className={
            superAdminShow ? "list-group-item  d-flex flex-column align-items-center list-group-item-href" : "hidden"
          }
          to="/tenant"
        >
          <i className="pi pi-users"></i>
          Tenants
        </NavLink>

        <NavLink activeclassname="side-active"  isActive={checkTopicSectionActive}  to="/topics"
            className={superAdminShow ? "list-group-item  d-flex flex-column align-items-center list-group-item-href" : "hidden"} >
          <i className="pi pi-book"></i>
                Topics
        </NavLink>

        <NavLink activeclassname="side-active" to="/analytics"
            className={superAdminShow ? "list-group-item  d-flex flex-column align-items-center list-group-item-href" : "hidden"} >
          <i className="pi pi-chart-bar"></i>
            Analytics
        </NavLink>

        <NavLink activeclassname="side-active"  isActive={checkLibrarySectionActive}  to="/library"
            className={superAdminShow ? "list-group-item  d-flex flex-column align-items-center list-group-item-href" : "hidden"} >
          <i className="pi pi-folder"></i>
            Library
        </NavLink>

        {/* <NavLink activeclassName="side-active"
          className={
            superAdminShow ? "list-group-item list-group-item-href" : "hidden"
          }
          href="/topic"
        >
          <span className="icon icon-person me-2"></span>
          Topic List
        </NavLink> */}


        <NavLink activeclassname="side-active"
          className={
            tenantAdminShow ? "list-group-item  d-flex flex-column align-items-center list-group-item-href" : "hidden"
          }
          to="/user"
        >
          <i className="pi pi-user"></i>
          Users
        </NavLink>
        {/* <a href="#" className="list-group-item list-group-item-action">
          <span className="icon icon-error_outline me-2"></span>
          Events
        </a> */}
        {/* <a href="#" className="list-group-item list-group-item-action">
          <span className="icon icon-add_circle me-2"></span>
          Profile
        </a> */}
        {/* <a href="#" className="list-group-item list-group-item-action">
          <span className="icon icon-folder_open me-2"></span>
          Status
        </a> */}
      </div>
    </div>
  );
}
export default Sidebar;
