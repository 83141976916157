import React,{useEffect} from "react";
import { Redirect, Route } from "react-router-dom";
import { useGlobalState } from '../Context'
import ProgressBarWrapper from '../Shared/Components/ProgressBar'
import { useHistory } from 'react-router-dom';
const AppRoutes = ({ component: Component, path, isPrivate, permissions, ...rest }) => {
    const userDetails = useGlobalState().user;
    const history =useHistory()
    useEffect(() => {
      if(!userDetails.token){
        history.push('login')
      }
    
      return () => {
      }
    }, [userDetails])
    return (
        <Route
            path={path}
            render={props => {
                if (Boolean(userDetails.token) && (path === '/' || path === '/login')) {
                    return <Redirect to={{ pathname: "/dashboard" }} />;
                } else if ((isPrivate && !Boolean(userDetails.token)) || path === '/') {
                    return <Redirect to={{ pathname: "/login" }} />
                } else if(isPrivate && !permissions.includes(userDetails.userRole)) {
                    return <Redirect to={{ pathname: "/dashboard" }} />;
                }else {
                    return <>
                        <ProgressBarWrapper />
                        <Component {...props} {...rest} />
                    </>
                }
            }}
            {...rest}
        />
    );
};

export default AppRoutes;