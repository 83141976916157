import { ManageLocalStorage } from "../Services/ManageLocalStorage";

const initialStateValue = ManageLocalStorage.get("userDetails")
  ? JSON.parse(ManageLocalStorage.get("userDetails"))
  : "";

export const initialState = initialStateValue
  ? initialStateValue
  : {
      userDetails: "",
      token: "",
      userRole: "",
      errorMessage: null,
    };

export const GlobalReducer = (initialState, action) => {
  // console.log(initialState);
  switch (action.type) {
    case "REQUEST_LOGIN":
      return {
        ...initialState,
      };
    case "LOGIN_SUCCESS":
      const stateValue = {
        ...action.payload,
        ...initialState,
        user: action.payload.username,
        token: action.payload.signInUserSession.accessToken.jwtToken,
        userRole: action.payload.attributes["custom:user_role"],
        name: action.payload.attributes.name,
        // toggle: 1,
      };
      ManageLocalStorage.set("userDetails", stateValue);
      return stateValue;
    case "NEW_PASSWORD_REQUIRED":
      const resetStateValue = {
        ...action.payload,
        ...initialState,
        user: action.payload,
      };
      ManageLocalStorage.set("userDetailsForNewTenant", resetStateValue);
      return resetStateValue;
    case "LOGOUT":
      return {
        initialState : {},
        user: "",
        userRole: "",
        token: "",
        name: "",
      };

    case "LOGIN_ERROR":
      return {
        ...initialState,
        errorMessage: action.error,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
